import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { get } from 'lodash';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  studies: [],
  fieldUsers: [],
  locations: [],
  roles: [],
  singleStudy: null,
  singleStudyData: null,
  isLoading: true,
  singleStudyLoading: true,
  addEcLoading: false,
  markStudyLoading: false,
  updateStudyLoading: false,
  updateObservationLoading: false,
  addObservationLoading: false,
  isMassLoading: false,
  isAdding: false,
  globalFilterName: '',
  globalOrder: 'asc',
  globalOrderBy: '',
  isStudyPohotsDownloading: false,
  isDownloadLinksLoading: false,
  isSelectedStudyLoading: false,
  downloadLinks: [],
};
const cookies = new Cookies();
const jwtToken = cookies.get('token');
const Role = jwtToken && jwtDecode(jwtToken);
const role = String(Role?.role) || '0';
const getResponseMessage = (successCount, rejectedCount) => {
  if (rejectedCount <= 0) {
    return 'All the observations are successfully updated';
  }
  if (successCount <= 0) {
    return 'Unfortunately, all observations failed to update';
  }
  return `${successCount} entries were successfully edited, while ${rejectedCount} entries were not accepted`;
};
export const getStudies = createAsyncThunk('studies/getStudies', async (payload, { rejectWithValue }) => {
  try {
    const url = get(payload, 'status') ? `studies/get?status=${get(payload, 'status', '')}` : 'studies/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const downloadStudyPhotos = createAsyncThunk('studies/downloadStudyPhotos', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/download/photos';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const downloadSelectedStudy = createAsyncThunk('studies/downloadSelectedStudy', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/download/studies';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const downloadProjectPhotos = createAsyncThunk('studies/downloadProjectPhotos', async (payload, { rejectWithValue }) => {
  try {
    const url = 'projects/download/photos';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const getDownloadLinks = createAsyncThunk('studies/getDownloadLinks', async (payload, { rejectWithValue }) => {
  try {
    const url = 'downloads/get/links';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addMissingObservations = createAsyncThunk('studies/addMissingObservations', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/add/obs';
    await axios
      .all(
        payload?.selectedRows.map((data) =>
          axiosInstance({
            url,
            method: 'POST',
            data,
          })
        )
      )
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          payload?.closeMissingModal();
          const message = get(responseOne, 'data.message') || 'successfully added observation';
          payload.snackBarMessage(message);
          // use/access the results
        })
      )
      .catch((errors) => {
        console.log(errors);
      });

    // const response = await axiosInstance({
    //   url,
    //   method: 'POST',
    //   data: payload,
    // });
    // const data = await response?.data;
    return null;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const massEditObservation = createAsyncThunk('studies/massEditObservation', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/update/obs';
    const response = await axiosInstance({
      url,
      method: 'PATCH',
      data: payload,
    });
    const data = await response?.data;
    return { ...data, payload };
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const getSingleStudyData = createAsyncThunk('studies/getSingleStudyData', async (values, { rejectWithValue }) => {
  const { _id, ...rest } = values;
  const payload = rest;
  try {
    const url = `studies/get/${get(values, '_id', '')}`;
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const updateStudy = createAsyncThunk('studies/updateStudy', async (values, { rejectWithValue }) => {
  const { _id, locationName, roleName, ...rest } = values;
  const payload = rest;
  try {
    const url = `studies/update/${_id}`;
    const response = await axiosInstance({
      url,
      method: 'PATCH',
      data: payload,
    });
    const data = await response?.data;
    return { ...data, values };
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const markStudyReviewed = createAsyncThunk('studyLogs/markStudyReviewed', async (payload, { rejectWithValue }) => {
  try {
    const { role: currentRole, ...rest } = payload;
    const url = 'studylog/review';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: rest,
    });
    const data = await response?.data;
    return { ...data, currentRole };
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addToObservation = createAsyncThunk('studyLogs/addToObservation', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/add/obs';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addEc = createAsyncThunk('studyLogs/addEc', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/add/ec';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const updateObservation = createAsyncThunk('studies/updateObservation', async (values, { rejectWithValue }) => {
  // const { _id, elementName, roleName, areaName, taskName, ...rest } = values;
  const { _id, projectID, studyType, ...rest } = values;
  // const payload = rest;
  const payload = {
    studyType,
    projectID,
    observations: [
      {
        observationID: _id,
        ...rest,
      },
    ],
  };
  try {
    // const url = `studies/update/obs/${_id}`;
    const url = 'studies/update/obs';
    const response = await axiosInstance({
      url,
      method: 'PATCH',
      data: payload,
    });
    const data = await response?.data;
    return { ...data, values };
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addInvalidObservation = createAsyncThunk('studies/addInvalidObservation', async (values, { rejectWithValue }) => {
  // const { _id, elementName, roleName, areaName, taskName, ...rest } = values;
  try {
    // const url = `studies/update/obs/${_id}`;
    const url = 'studies/add/invalid/obs';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: values,
    });
    const data = await response?.data;
    return { ...data, values };
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

// { studyID: row?.studyID, roundID: row?.roundID, observationIDs: [row?._id], studyType: row?.studyType };

const studiesSlice = createSlice({
  name: 'studies',
  initialState,
  reducers: {
    updateSingleStudy: (state, action) => {
      state.singleStudy = action.payload && action.payload;
    },
    removeSingleStudy: (state, action) => {
      if (action?.payload) {
        if (action.payload.studyType === 1) {
          const index = state.singleStudyData.rounds.findIndex((round) => round.roundID === action.payload.roundID);
          const newData = state.singleStudyData.rounds[index].data?.filter(
            (currentStudy) => !action.payload.observationIDs.includes(currentStudy?.observationID)
          );
          state.singleStudyData.rounds[index].data = newData;
        } else if (action.payload.studyType === 2 || action.payload.studyType === 3) {
          const newData = state.singleStudyData?.data.filter(
            (singleStudy) => !action.payload.observationIDs.includes(singleStudy?.observationID)
          );
          state.singleStudyData.data = newData;
        }
      }
    },
    removeStudies: (state, action) => {
      if (state.studies?.length > 0 && action?.payload?.length > 0) {
        const newStudies = state.studies.filter((study) => !action.payload.includes(study?._id));
        state.studies = newStudies;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudies.pending, (state, action) => {
      state.isLoading = true;
      state.studies = [];
      state.singleStudy = null;
      state.singleStudyData = null;
    });
    builder.addCase(getStudies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.studies = action.payload.data;
    });
    builder.addCase(getStudies.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSingleStudyData.pending, (state, action) => {
      state.singleStudyLoading = true;
      state.singleStudyData = null;
    });
    builder.addCase(getSingleStudyData.fulfilled, (state, action) => {
      state.singleStudyLoading = false;
      state.singleStudyData = action.payload.data;
    });
    builder.addCase(getSingleStudyData.rejected, (state, action) => {
      state.singleStudyLoading = false;
    });
    builder.addCase(downloadStudyPhotos.pending, (state, action) => {
      state.isStudyPohotsDownloading = true;
    });
    builder.addCase(downloadStudyPhotos.fulfilled, (state, action) => {
      state.isStudyPohotsDownloading = false;
    });
    builder.addCase(downloadStudyPhotos.rejected, (state, action) => {
      state.isStudyPohotsDownloading = false;
    });
    builder.addCase(getDownloadLinks.pending, (state, action) => {
      state.isDownloadLinksLoading = true;
    });
    builder.addCase(getDownloadLinks.fulfilled, (state, action) => {
      state.isDownloadLinksLoading = false;
      state.downloadLinks = action.payload.data;
    });
    builder.addCase(getDownloadLinks.rejected, (state, action) => {
      state.isDownloadLinksLoading = false;
      state.downloadLinks = [];
    });
    builder.addCase(updateStudy.pending, (state, action) => {
      state.updateStudyLoading = true;
    });
    builder.addCase(updateStudy.fulfilled, (state, action) => {
      state.updateStudyLoading = false;
      const updatedValues = action?.payload?.values;
      const studyId = updatedValues?._id;

      if (!studyId) {
        console.error('studyId is missing in payload:', updatedValues);
        return;
      }

      if (!Array.isArray(state.studies)) {
        console.error('state.studies is not an array:', state.studies);
        return;
      }
      // ✅ Replace the object inside the array instead of modifying it in place
      state.studies = state.studies.map((s) =>
        s._id === studyId ? { ...s, ...updatedValues, name: action?.payload?.values?.studyName } : s
      );
    });
    builder.addCase(updateStudy.rejected, (state, action) => {
      state.updateStudyLoading = false;
    });
    builder.addCase(addInvalidObservation.pending, (state, action) => {
      state.updateObservationLoading = true;
    });
    builder.addCase(addInvalidObservation.fulfilled, (state, action) => {
      state.updateObservationLoading = false;
    });
    builder.addCase(addInvalidObservation.rejected, (state, action) => {
      state.updateObservationLoading = false;
    });
    builder.addCase(updateObservation.pending, (state, action) => {
      state.updateObservationLoading = true;
    });
    builder.addCase(updateObservation.fulfilled, (state, action) => {
      if (action.payload?.values) {
        if (action.payload.values?.studyType === 1) {
          if (state.singleStudyData?.rounds?.length > 0) {
            state.singleStudyData.rounds.forEach((round) => {
              if (round?.data?.length > 0) {
                round.data.forEach((observation) => {
                  if (observation.observationID === action.payload.values._id) {
                    observation.frequency = get(action, 'payload.values.frequency');
                    observation.areaID = get(action, 'payload.values.areaID');
                    observation.roleID = get(action, 'payload.values.roleID');
                    observation.rating = get(action, 'payload.values.rating');
                    observation.elementID = get(action, 'payload.values.elementID');
                    observation.notes = get(action, 'payload.values.notes');
                    observation.elementName = get(action, 'payload.values.elementName');
                    observation.areaName = get(action, 'payload.values.areaName');
                    observation.roleName = get(action, 'payload.values.roleName');
                    observation.categoryName = get(action, 'payload.values.categoryName');
                    observation.categoryID = get(action, 'payload.values.categoryID');
                  }
                });
              }
            });
          }
        } else if (state.singleStudyData?.data?.length > 0) {
          state.singleStudyData.data.forEach((observation) => {
            if (observation.observationID === action.payload.values._id) {
              observation.frequency = get(action, 'payload.values.frequency');
              observation.areaID = get(action, 'payload.values.areaID');
              observation.taskID = get(action, 'payload.values.taskID');
              observation.rating = get(action, 'payload.values.rating');
              observation.elementID = get(action, 'payload.values.elementID');
              observation.notes = get(action, 'payload.values.notes');
              observation.elementName = get(action, 'payload.values.elementName');
              observation.areaName = get(action, 'payload.values.areaName');
              observation.taskName = get(action, 'payload.values.taskName');
              observation.categoryName = get(action, 'payload.values.categoryName');
              observation.categoryID = get(action, 'payload.values.categoryID');
            }
          });
        }
      }
      state.updateObservationLoading = false;
    });
    builder.addCase(updateObservation.rejected, (state, action) => {
      state.updateObservationLoading = false;
    });
    builder.addCase(markStudyReviewed.pending, (state, action) => {
      state.markStudyLoading = true;
    });
    builder.addCase(markStudyReviewed.fulfilled, (state, action) => {
      state.markStudyLoading = false;
      if (get(action, 'payload.data') && action?.payload?.data?.length > 0) {
        action?.payload?.data?.forEach((currnetStudy, index) => {
          const reqIndex = state.studies.findIndex((study) => study?._id === currnetStudy?.studyID);
          if ((get(action, 'payload.currentRole') || role) && (get(action, 'payload.currentRole') || role) === '3') {
            state.studies[reqIndex].isFieldUserReviewed = true;
          } else {
            state.studies[reqIndex].isReviewed = true;
          }
        });
      }
      // if (role && role === '3') {
      // }
    });
    builder.addCase(markStudyReviewed.rejected, (state, action) => {
      state.markStudyLoading = false;
    });
    builder.addCase(addEc.pending, (state, action) => {
      state.addEcLoading = true;
    });
    builder.addCase(addEc.fulfilled, (state, action) => {
      state.addEcLoading = false;
    });
    builder.addCase(addEc.rejected, (state, action) => {
      state.addEcLoading = false;
    });
    builder.addCase(addToObservation.pending, (state, action) => {
      state.addObservationLoading = true;
    });
    builder.addCase(addToObservation.fulfilled, (state, action) => {
      state.addObservationLoading = false;
    });
    builder.addCase(addToObservation.rejected, (state, action) => {
      state.addObservationLoading = false;
    });
    builder.addCase(massEditObservation.pending, (state, action) => {
      state.isMassLoading = true;
    });
    builder.addCase(massEditObservation.fulfilled, (state, action) => {
      state.isMassLoading = false;
      if (action.payload?.payload && action?.payload?.payload?.observations?.length > 0) {
        if (action.payload.payload?.studyType === 1) {
          if (state.singleStudyData?.rounds?.length > 0) {
            state.singleStudyData.rounds.forEach((round) => {
              if (round?.data?.length > 0) {
                round.data.forEach((observation) => {
                  const reqObs = action?.payload?.payload?.observations?.find(
                    (obs) => obs?.observationID === observation?.observationID
                  );
                  if (reqObs) {
                    observation.frequency = get(reqObs, 'frequency');
                    observation.areaID = get(reqObs, 'areaID');
                    observation.roleID = get(reqObs, 'roleID');
                    observation.rating = get(reqObs, 'rating');
                    observation.elementID = get(reqObs, 'elementID');
                    observation.notes = get(reqObs, 'notes');
                    observation.elementName = get(reqObs, 'elementName');
                    observation.areaName = get(reqObs, 'areaName');
                    observation.roleName = get(reqObs, 'roleName');
                    observation.categoryID = get(reqObs, 'categoryID');
                    observation.categoryName = get(reqObs, 'categoryName');
                  }
                });
              }
            });
          }
        } else if (state.singleStudyData?.data?.length > 0) {
          state.singleStudyData.data.forEach((observation) => {
            const reqObs = action?.payload?.payload?.observations?.find(
              (obs) => obs?.observationID === observation?.observationID
            );
            if (reqObs) {
              observation.frequency = get(reqObs, 'frequency');
              observation.areaID = get(reqObs, 'areaID');
              observation.taskID = get(reqObs, 'taskID');
              observation.rating = get(reqObs, 'rating');
              observation.elementID = get(reqObs, 'elementID');
              observation.notes = get(reqObs, 'notes');
              observation.elementName = get(reqObs, 'elementName');
              observation.areaName = get(reqObs, 'areaName');
              observation.taskName = get(reqObs, 'taskName');
              observation.categoryID = get(reqObs, 'categoryID');
              observation.categoryName = get(reqObs, 'categoryName');
            }
          });
        }
      }
    });
    builder.addCase(massEditObservation.rejected, (state, action) => {
      state.isMassLoading = false;
    });
    builder.addCase(downloadSelectedStudy.pending, (state, action) => {
      state.isSelectedStudyLoading = true;
    });
    builder.addCase(downloadSelectedStudy.fulfilled, (state, action) => {
      state.isSelectedStudyLoading = false;
    });
    builder.addCase(downloadSelectedStudy.rejected, (state, action) => {
      state.isSelectedStudyLoading = false;
    });
  },
});
export const { updateSingleStudy, removeSingleStudy, removeStudies } = studiesSlice.actions;
export default studiesSlice.reducer;
