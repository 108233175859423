import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  DialogActions,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { get, has, isEmpty, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import ModalComponent from '../../../components/ModalComponent';
import { ThemeButton } from '../../../components/button';
import FormInput from '../../../components/formComponents/FormInput';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../../components/status';
import DataTable from '../../../components/table';
import { Types } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';
import { addLocationSchema } from '../../../utils/schema';
import { getTags } from '../tags/tagsSlice';
import { addLocation, getLocations, updateLocation } from './locationSlice';
import ThemeTimePicker from './ThemeTimePicker';
import { BootstrapTooltip } from '../../../components/ThemeTooltip';

const LocationsPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { locations, isLoading, isAdding } = useSelector((state) => state.location);
  const { tags } = useSelector((state) => state.tags);
  const [delayedLoading, setDelayedLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, 100);

      return () => clearTimeout(timer); // ✅ Return cleanup function
    }
    setDelayedLoading(true);
    return undefined;
  }, [isLoading]);
  const headCells = [
    {
      id: 'name',
      numeric: false,
      // disablePadding: true,
      label: 'Name',
      maxWidth: 160,
      // titleCase: true,
      renderElement: (cell) => (
        <Stack>
          <Typography>{cell?.name}</Typography>
          <Typography variant="caption" sx={{ color: '#3c4555' }}>
            {`${get(cell, 'projectSetting.address', '')} ${has(cell, 'projectSetting.address') && has(cell, 'projectSetting.postcode') ? ' ,' : ''} ${get(cell, 'projectSetting.postcode', '')}`}
            {cell?.locations &&
              cell?.locations.length > 0 &&
              cell.locations.map((location, index) => `${index !== 0 ? ', ' : ''}${location?.name} `)}
          </Typography>
        </Stack>
      ),
      // align: 'left',
    },
    {
      id: 'contactName',
      disablePadding: false,
      label: 'Contact Name ',
    },
    {
      id: 'locationTags',
      disablePadding: false,
      label: 'Tag',
      width: 500,
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => has(cell, 'projectSetting.status') && <StatusIndicator cell={cell?.projectSetting} />,
      // width: '15%',
    },
  ];
  const [locationRows, setLocationRows] = useState([]);
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [isModalopen, setisModalopen] = useState(false);
  const [Status, setStatus] = useState(ACTIVE);
  const [rowToEdit, setRowToEdit] = useState('');
  const regularLocations = useMemo(
    () =>
      !isEmpty(locations) &&
      !!locations?.length &&
      locations.reduce(
        (acc, location) => {
          const locationObj = {
            ...location,
            contactName: startCase(get(location, 'projectSetting.contactName', '')),
            status: get(location, 'projectSetting.status'),
            locationTags:
              tags &&
              tags?.length >= 0 &&
              get(location, 'projectSetting.tags') &&
              location.projectSetting.tags.length > 0 &&
              location.projectSetting.tags.map((locationTag) => tags.find((tag) => tag?._id === locationTag)?.name)?.join(', '),
          };
          if (location?.projectSetting?.status === 'active') {
            acc.active.push(locationObj);
          } else if (location?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(locationObj);
          }
          acc.default.push(locationObj);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [locations, tags]
  );
  const form = useForm({
    defaultValues: {
      name: '',
      postcode: '',
      address: '',
      contactName: '',
      telephone: '',
      tags: [],
      schedule: null,
    },
    resolver: yupResolver(addLocationSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'tags', // unique name for your Field Array
  });

  const fieldValues = watch('tags');
  const openModal = () => {
    setisModalopen(true);
  };
  const tagsValues = watch('tags');
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    reset({ tags: [] });
    // reset({ permission: '' });
    // setShowPassword(false);
    setValue('schedule', null);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  useEffect(() => {
    if (id) {
      dispatch(getLocations({ projectID: id }));
      dispatch(getTags({ projectID }));
    }
  }, [projectID, dispatch, id]);
  useEffect(() => {
    if (regularLocations) {
      if (Status === DEFAULT) {
        setLocationRows(regularLocations?.default);
      } else if (Status === ARCHIVED) {
        setLocationRows(regularLocations?.archive);
      } else if (Status === ACTIVE) {
        setLocationRows(regularLocations?.active);
      }
    } else {
      setLocationRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularLocations]);
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setLocationRows(regularLocations.default);
      } else if (value === ACTIVE) {
        setLocationRows(regularLocations.active);
      } else if (value === ARCHIVED) {
        setLocationRows(regularLocations.archive);
      }
    }
  };
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Location',
    openModal,
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'locations/update/status',
          method: 'PATCH',
          data: { locationIDs: [row?._id], status, projectID: id },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Location`,
            })
          );
          dispatch(getLocations({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onSubmit = (values) => {
    if (customerID && projectID) {
      if (rowToEdit) {
        dispatch(updateLocation({ ...values, customerID, projectID, _id: rowToEdit }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(getTags({ projectID }));
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Field User Added Successfully'),
                })
              );
              dispatch(getLocations({ projectID: id }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      } else {
        dispatch(addLocation({ ...values, customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(getTags({ projectID }));
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Field User Added Successfully'),
                })
              );
              dispatch(getLocations({ projectID: id }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      const locationToEdit = locations.find((location) => location?._id === row?._id);
      setValue('name', get(locationToEdit, 'name', ''));
      setValue('address', get(locationToEdit, 'projectSetting.address', ''));
      setValue('postcode', get(locationToEdit, 'projectSetting.postcode', ''));
      setValue('contactName', get(locationToEdit, 'projectSetting.contactName', ''));
      setValue('telephone', get(locationToEdit, 'projectSetting.telephone', ''));
      setValue('tags', get(locationToEdit, 'projectSetting.tags', []));
      // setValue('schedule', get(locationToEdit, 'projectSetting.schedule', null));
      const schedule = get(locationToEdit, 'projectSetting.schedule', {});
      const timeFormatRegex = /^\d{1,2}:\d{1,2}$/;
      const filteredSchedule = Object.fromEntries(Object.entries(schedule).filter(([_, value]) => timeFormatRegex.test(value)));
      setValue('schedule', filteredSchedule);
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `locations/delete/${row?._id}`,
          method: 'DELETE',
          data: { projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getLocations({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const tagsOptions = useMemo(() => {
    if (tags && !!tags?.length) {
      return tags.map((tag) => ({ label: tag?.name, value: tag?._id }));
    }
    return [];
  }, [tags]);
  // const locationRows = useMemo(() => {
  //   if (locations && !!locations?.length) {
  //     return locations.map((location) => ({
  //       ...location,
  //       contactName: startCase(get(location, 'projectSetting.contactName', '')),
  //     }));
  //   }
  //   return [];
  // }, [locations]);
  const sheduleValue = watch('schedule');
  const isMondayValuesFilled = useMemo(
    () => !!(sheduleValue?.monOpen && sheduleValue?.monClose),
    [sheduleValue?.monClose, sheduleValue?.monOpen]
  );
  const handleFillAllValues = () => {
    const { monOpen, monClose } = getValues('schedule'); // Get Monday's values

    setValue('schedule', {
      monOpen,
      monClose,
      tueOpen: monOpen,
      tueClose: monClose,
      wedOpen: monOpen,
      wedClose: monClose,
      thuOpen: monOpen,
      thuClose: monClose,
      friOpen: monOpen,
      friClose: monClose,
      satOpen: monOpen,
      satClose: monClose,
      sunOpen: monOpen,
      sunClose: monClose,
    });
  };
  const filter = createFilterOptions();
  return (
    <Box>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1400px !important',
            // maxHeight: '700px',
          },
        }}
        title={rowToEdit ? 'Edit Location' : 'Create Location'}
        // title="Add Location"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ m: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isAdding} type="submit" onClick={handleSubmit(onSubmit)} variant="contained">
              {/* {rowToEdit ? 'Save Changes' : 'Create industry'} */}
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid rowSpacing={1} columnSpacing={5} container>
              <Grid className="left-side" item xs={12} md={6} lg={6}>
                <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
                  <Grid item lg={12} sm={12} xs={12} md={4}>
                    <FormInput
                      label="Location name"
                      placeholder="Enter Name"
                      InputProps={{
                        ...register('name'),
                      }}
                      error={!!errors?.name}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12} md={4}>
                    <FormInput
                      label="Contact (optional)"
                      placeholder="Enter Contact Name"
                      InputProps={{
                        ...register('contactName'),
                      }}
                      error={!!errors?.contactName}
                      helperText={errors?.contactName && errors?.contactName?.message}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12} md={4}>
                    <FormInput
                      label="Address"
                      placeholder="Enter Address"
                      InputProps={{
                        ...register('address'),
                      }}
                      error={!!errors?.address}
                      helperText={errors?.address && errors?.address?.message}
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} xs={12} md={4}>
                    <FormInput
                      label="Phone (optional)"
                      placeholder="Enter Phone"
                      InputProps={{
                        ...register('telephone'),
                      }}
                      error={!!errors?.telephone}
                      helperText={errors?.telephone && errors?.telephone?.message}
                    />
                  </Grid>
                  <Grid item lg={6} sm={12} xs={12} md={4}>
                    <FormInput
                      label="Post Code"
                      placeholder="Enter Post Code"
                      InputProps={{
                        ...register('postcode'),
                      }}
                      error={!!errors?.postcode}
                      helperText={errors?.postcode && errors?.postcode?.message}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12} md={4}>
                    {fields?.length > 0 && (
                      <Typography sx={{ mb: 1 }} variant="subtitle2">
                        Location Tags
                      </Typography>
                    )}
                    {fields.map((currentField, index) => (
                      <Stack justifyContent="center" direction="column" gap mb={1}>
                        <Typography variant="caption">Tag {index + 1}</Typography>
                        <Stack key={fieldValues[index]} gap={2} direction="row" alignItems="center">
                          <Controller
                            control={control}
                            name={`tags.${index}`}
                            // key={index}
                            // defaultValue={defaultValue}
                            render={({ field }) => (
                              <Autocomplete
                                disablePortal
                                freeSolo
                                // defaultValue={tagsOptions.find((opt) => opt?.value === field?.value)}
                                value={tagsOptions.find((opt) => opt?.value === field?.value) || field?.value}
                                // value={field?.value}
                                onChange={(event, newValue) => {
                                  // setValue(`tags.${index}`, newValue);
                                  if (typeof newValue === 'string') {
                                    field.onChange(newValue);
                                  } else if (newValue && newValue.value) {
                                    // Create a new value from the user input
                                    field.onChange(newValue.value);
                                  } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    field.onChange(newValue.inputValue);
                                  } else {
                                    field.onChange(newValue);
                                  }
                                  // field.onChange(newValue?.value);
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  const { inputValue } = params;
                                  // Suggest the creation of a new value
                                  const isExisting = options.some((option) => inputValue === option.label);
                                  if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                      inputValue,
                                      label: `Add ${inputValue}`,
                                    });
                                  }
                                  return filtered;
                                }}
                                // getOptionLabel={(option) => option.label}
                                // inputValue={!isId ? field : ''}
                                // onInputChange={(event, newInputValue) => {
                                //   setValue(`tags.${index}`, newInputValue);
                                // }}
                                options={tagsOptions}
                                getOptionDisabled={(option) => tagsValues.some((value) => value === option?.value)}
                                getOptionLabel={(option) => {
                                  // Value selected with enter, right from the input
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  // Add "xxx" option created dynamically
                                  if (option.inputValue) {
                                    // return option.inputValue;
                                    return option.inputValue;
                                  }
                                  // Regular option
                                  return option.label;
                                }}
                                id="controllable-states-demo"
                                sx={{ width: 300 }}
                                renderInput={(params) => {
                                  const { InputProps, ...rest } = params;
                                  return (
                                    <TextField
                                      id="autoSelectTextField"
                                      InputProps={{
                                        ...InputProps,
                                        size: 'small',
                                        sx: {
                                          borderRadius: 25,
                                          height: 44,
                                          fontSize: '0.8600rem',
                                        },
                                      }}
                                      placeholder="Select or add new tag"
                                      {...rest}
                                    />
                                  );
                                }}
                              />
                            )}
                          />
                          <IconButton onClick={() => remove(index)} sx={{ mt: 0 }}>
                            <CancelIcon color="error" />
                          </IconButton>
                        </Stack>
                        {index === fields.length - 1 && fieldValues[fieldValues.length - 1] === '' && (
                          <Typography variant="body2" sx={{ color: 'warning.dark' }}>
                            Please Select the value from the dropdown if adding new tag
                          </Typography>
                        )}
                        <Typography variant="caption" sx={{ color: 'error.main' }}>
                          {!!errors?.tags && errors.tags[index]?.message && errors.tags[index]?.message}
                        </Typography>
                      </Stack>
                    ))}
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12} md={12}>
                    <Stack direction="row" width="100%" justifyContent="flex-end">
                      <Button onClick={() => append('')}>Add Tag</Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="right-side" item xs={12} md={6} lg={6}>
                <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
                  <Grid item lg={5.4} sm={12}>
                    <Stack width="100%" gap={2} alignItems="center" direction="row">
                      <Typography variant="subtitle2">Monday</Typography>
                      <BootstrapTooltip placement="right" enterDelay={300} title="Fill All Values">
                        <IconButton disabled={!isMondayValuesFilled} onClick={handleFillAllValues} color="primary" size="small">
                          <ContentPasteGoIcon fontSize="8px" />
                        </IconButton>
                      </BootstrapTooltip>
                    </Stack>
                    <Stack width="100%" gap={2} justifyContent="space-between" direction="row">
                      <ThemeTimePicker label="Open Hours" name="schedule.monOpen" control={control} />
                      <ThemeTimePicker label="Close Hours" name="schedule.monClose" control={control} />
                    </Stack>
                  </Grid>
                  <Grid item lg={5.4} sm={12}>
                    <Typography variant="subtitle2">Tuesday</Typography>
                    <Stack width="100%" gap={2} justifyContent="space-between" direction="row">
                      <ThemeTimePicker label="Open Hours" name="schedule.tueOpen" control={control} />
                      <ThemeTimePicker label="Close Hours" name="schedule.tueClose" control={control} />
                    </Stack>
                  </Grid>
                  <Grid item lg={5.4} sm={12}>
                    <Typography variant="subtitle2">Wednesday</Typography>
                    <Stack width="100%" gap={2} justifyContent="space-between" direction="row">
                      <ThemeTimePicker label="Open Hours" name="schedule.wedOpen" control={control} />
                      <ThemeTimePicker label="Close Hours" name="schedule.wedClose" control={control} />
                    </Stack>
                  </Grid>
                  <Grid item lg={5.4} sm={12}>
                    <Typography variant="subtitle2">Thursday</Typography>
                    <Stack width="100%" gap={2} justifyContent="space-between" direction="row">
                      <ThemeTimePicker label="Open Hours" name="schedule.thuOpen" control={control} />
                      <ThemeTimePicker label="Close Hours" name="schedule.thuClose" control={control} />
                    </Stack>
                  </Grid>
                  <Grid item lg={5.4} sm={12}>
                    <Typography variant="subtitle2">Friday</Typography>
                    <Stack width="100%" gap={2} justifyContent="space-between" direction="row">
                      <ThemeTimePicker label="Open Hours" name="schedule.friOpen" control={control} />
                      <ThemeTimePicker label="Close Hours" name="schedule.friClose" control={control} />
                    </Stack>
                  </Grid>
                  <Grid item lg={5.4} sm={12}>
                    <Typography variant="subtitle2">Saturday</Typography>
                    <Stack width="100%" gap={2} justifyContent="space-between" direction="row">
                      <ThemeTimePicker label="Open Hours" name="schedule.satOpen" control={control} />
                      <ThemeTimePicker label="Close Hours" name="schedule.satClose" control={control} />
                    </Stack>
                  </Grid>
                  <Grid item lg={5.4} sm={12}>
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Stack width="100%" gap={2} justifyContent="space-between" direction="row">
                      <ThemeTimePicker label="Open Hours" name="schedule.sunOpen" control={control} />
                      <ThemeTimePicker label="Close Hours" name="schedule.sunClose" control={control} />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={delayedLoading}
        headCells={headCells}
        rows={locationRows}
        searchLable="Search Locations"
        onRowStatusChange={onRowStatusChange}
        onEditClick={onEditClick}
        // handleSelectionClick={handleSelectionClick}
        filterKeys={['name']}
        customToolbar={Types.toolbarTypes.LOCATIONS}
        maxHeight={453}
        toolbarProps={toolbarProps}
        onRowDelete={onDelete}
        defaultRowsPerPage={10}
      />
      <DevTool control={control} />
    </Box>
  );
};

LocationsPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};

export default LocationsPage;
