import { Divider, FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, toggleGroupView } from '../../pages/projectList/projectSlice';
import { Types } from '../../types';
import axiosInstance from '../../utils/axiosInstance';
import ConfirmDialog from '../ConfirmDialog';
import Iconify from '../Iconify';
import { ActionButton, ThemeButton } from '../button';
import StatusButtons from '../buttonGroup';
import ThemeCheckbox from '../checkbox/themeChekbox';
import FormDatePicker from '../formComponents/formDatePicker';
import { setSnackbar } from '../snackbar/snackbarSlice';

const ProjectToolbar = (props) => {
  const {
    selected,
    filterName,
    onFilterName,
    searchLable,
    toolbarProps,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    handleClearSelection,
    handleCloseBackdrop,
    handleOpenBackdrop,
  } = props;
  const numSelected = selected?.length;
  const { isGroupViewToggled } = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('active');
  const handleStatusButtonClick = (selectedIds, status) => {
    setCurrentStatus(() => status);
    setIsStatusModalOpen(() => true);
  };
  const toggleGroup = (event, newValue) => {
    if (!isGroupViewToggled && (toDate || fromDate)) {
      setFromDate(null);
      setToDate(null);
    }
    dispatch(toggleGroupView(newValue));
  };
  const handleSelectionClick = async (selectedIds, status) => {
    setIsStatusModalOpen(false);
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (selectedIds && selectedIds?.length > 0) {
      handleOpenBackdrop();
      try {
        const response = await axiosInstance({
          url: 'projects/update/status',
          method: 'PATCH',
          data: { projectIDs: selected, status },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          handleClearSelection();
          dispatch(getProjects());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const handleDeleteElements = async () => {
    if (selected?.length > 0) {
      handleOpenBackdrop();
      setIsStatusModalOpen(false);
      try {
        const response = await axiosInstance({
          url: `projects/delete/${selected[0]}`,
          method: 'DELETE',
          data: { projectIDs: selected },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          handleClearSelection();
          dispatch(getProjects());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  return (
    <>
      <ConfirmDialog
        title={currentStatus === 'delete' ? 'Delete' : 'Change Status'}
        open={isStatusModalOpen}
        setOpen={setIsStatusModalOpen}
        selectedField={selected}
        onConfirm={() => (currentStatus === 'delete' ? handleDeleteElements() : handleSelectionClick(selected, currentStatus))}
        confirmText={currentStatus === 'delete' ? 'Delete' : 'Change Status'}
      >
        {currentStatus === 'delete' ? (
          <Typography variant="body1">
            Are you sure you want to <b>delete</b> this?
          </Typography>
        ) : (
          <Typography variant="body1">
            Are you sure you want to change the status to <b>{currentStatus}</b> ?
          </Typography>
        )}
      </ConfirmDialog>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 0.5,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}

        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            {toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="delete"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                color="error"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'delete', handleClearSelection)
                }
              >
                {`Remove ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="activate"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'active', handleClearSelection)
                }
              >
                {`Activate ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ACTIVE ? (
              <ActionButton
                type="archive"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                color="error"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'archive', handleClearSelection)
                }
              >
                {`Archive ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
          </Stack>
        ) : (
          <Grid p={1} mb={0} pb={0} container>
            <Grid item xs={12} md={12} lg={12} xl={8}>
              <Grid container spacing={2}>
                <Grid className="project-search-bar" item lg={4} md={4} xs={12}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      // size='small'
                      sx={{
                        fontSize: '0.86rem',
                        mt: '5px',
                        // mb: '16px',
                        borderRadius: 25,
                        lineHeight: '1.9375em',
                        '.MuiInputBase-input': {
                          height: '0.860em',
                          py: '15px',
                        },
                      }}
                      fullWidth
                      // size='small'
                      value={filterName}
                      onChange={onFilterName}
                      placeholder={searchLable || 'Search...'}
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid className="project-datepicker" item lg={5} md={6} xs={12}>
                  <Stack
                    className="custom-datepicker"
                    width="100%"
                    sx={{ '&.custom-datepicker .MuiTextField-root': { minWidth: 'unset !important' }, mt: '4px' }}
                    direction="row"
                  >
                    {/* <Box
                    className="custom-datepicker"
                    sx={{
                      '&.custom-datepicker .MuiTextField-root': { minWidth: 'unset' },
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  > */}
                    <FormDatePicker
                      mb={0.1}
                      range="left"
                      name="expiryDate"
                      value={fromDate}
                      onChange={(newDate) => setFromDate(newDate)}
                      placeholder="From"
                      onClear={() => setFromDate(null)}
                      height="1.8em !important"
                      // formLabel='Subscription End'
                    />
                    <FormDatePicker
                      mb={0.1}
                      range="right"
                      name="expiryDate"
                      value={toDate}
                      onChange={(newDate) => setToDate(newDate)}
                      placeholder="To"
                      onClear={() => setToDate(null)}
                      height="1.8em !important"
                      // formLabel='Subscription End'
                    />
                    {/* </Box> */}
                  </Stack>
                </Grid>

                <Grid pb={2} display="flex" alignItems="center" className="project-toggle-button" item lg={2.4} md={6} xs={12}>
                  <ThemeCheckbox label="Group projects" checked={isGroupViewToggled} onChange={toggleGroup} />
                </Grid>
              </Grid>
            </Grid>
            <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={4} lg={6}>
              <Stack
                gap={2}
                width="100%"
                sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                {/* <Typography>Status</Typography> */}
                <StatusButtons
                  status={toolbarProps?.status}
                  onStatusChange={toolbarProps?.onStatusChange}
                  fullWidth
                  size="small"
                  sx={{ width: '70%', mt: '5px', mb: '16px' }}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Toolbar>
      <Divider />
    </>
  );
};

ProjectToolbar.propTypes = {
  selected: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
  fromDate: PropTypes.any,
  setFromDate: PropTypes.any,
  setToDate: PropTypes.any,
  toDate: PropTypes.any,
  handleClearSelection: PropTypes.any,
};

export default ProjectToolbar;
