import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';
// import { projects } from './data';

const initialState = {
  isLoading: true,
  addUserLoading: false,
  projectFieldUsers: [],
  isOverviewLoading: false,
  overviewData: null,
};
export const getProjectFieldUsers = createAsyncThunk('project/getFieldUsers', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'projects/users',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const getProjectOverviews = createAsyncThunk('project/getOverviews', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'projects/overview',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

export const addFieldUser = createAsyncThunk('project/addFieldUser', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'projects/add/user',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateFieldUser = createAsyncThunk('project/updateFieldUser', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'projects/update/user',
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const projectUsersSlice = createSlice({
  name: 'projectUsers',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProjectFieldUsers.pending, (state, action) => {
      state.isLoading = true;
      state.projectFieldUsers = [];
    });
    builder.addCase(getProjectFieldUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectFieldUsers = action.payload?.data;
    });
    builder.addCase(getProjectFieldUsers.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addFieldUser.pending, (state, action) => {
      state.addUserLoading = true;
    });
    builder.addCase(addFieldUser.fulfilled, (state, action) => {
      state.addUserLoading = false;
    });
    builder.addCase(addFieldUser.rejected, (state, action) => {
      state.addUserLoading = false;
    });
    builder.addCase(updateFieldUser.pending, (state, action) => {
      state.addUserLoading = true;
    });
    builder.addCase(updateFieldUser.fulfilled, (state, action) => {
      state.addUserLoading = false;
    });
    builder.addCase(updateFieldUser.rejected, (state, action) => {
      state.addUserLoading = false;
    });
    builder.addCase(getProjectOverviews.pending, (state, action) => {
      state.isOverviewLoading = true;
      state.overviewData = null;
    });
    builder.addCase(getProjectOverviews.fulfilled, (state, action) => {
      state.isOverviewLoading = false;
      state.overviewData = action.payload?.data;
    });
    builder.addCase(getProjectOverviews.rejected, (state, action) => {
      state.isOverviewLoading = false;
    });
  },
});

export default projectUsersSlice.reducer;
