import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { updateObservation } from '../studiesPage/studiesSlice';

const initialState = {
  notes: [],
  isLoading: true,
  isNotesDownloading: false,
};

export const getNotes = createAsyncThunk('notes/getNotes', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/get/notes';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const downloadNote = createAsyncThunk('notes/downloadNote', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'projects/download/notes',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    updateSingleStudy: (state, action) => {
      state.singleStudy = action.payload && action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotes.pending, (state, action) => {
      state.isLoading = true;
      state.notes = [];
    });
    builder.addCase(getNotes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notes = action.payload.data;
    });
    builder.addCase(getNotes.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(downloadNote.pending, (state, action) => {
      state.isNotesDownloading = true;
    });
    builder.addCase(downloadNote.fulfilled, (state, action) => {
      state.isNotesDownloading = false;
    });
    builder.addCase(downloadNote.rejected, (state, action) => {
      state.isNotesDownloading = false;
    });
    builder.addCase(updateObservation.fulfilled, (state, action) => {
      if (action.payload?.values) {
        const updatedValues = action.payload.values;
        if (action.payload.values?.studyType === 1) {
          if (state.notes?.length > 0) {
            const noteIndex = state.notes.findIndex((note) => note.observationID === updatedValues._id);
            if (noteIndex !== -1) {
              // Update the note fields
              state.notes[noteIndex] = {
                ...state.notes[noteIndex], // Keep other existing properties
                frequency: updatedValues.frequency,
                areaID: updatedValues.areaID,
                roleID: updatedValues.roleID,
                rating: updatedValues.rating,
                elementID: updatedValues.elementID,
                notes: updatedValues.notes,
                elementName: updatedValues.elementName,
                areaName: updatedValues.areaName,
                roleName: updatedValues.roleName,
              };
            }
          }
        } else if (state.notes?.length > 0) {
          const noteIndex = state.notes.findIndex((note) => note.observationID === updatedValues._id);
          if (noteIndex !== -1) {
            // Update the note fields
            state.notes[noteIndex] = {
              ...state.notes[noteIndex], // Keep other existing properties
              frequency: updatedValues.frequency,
              areaID: updatedValues.areaID,
              taskID: updatedValues.taskID,
              rating: updatedValues.rating,
              elementID: updatedValues.elementID,
              notes: updatedValues.notes,
              elementName: updatedValues.elementName,
              areaName: updatedValues.areaName,
              taskName: updatedValues.taskName,
            };
          }
        }
      }
    });
  },
});
export default notesSlice.reducer;
