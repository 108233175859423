import DownloadIcon from '@mui/icons-material/Download';
import { FormControl, FormHelperText, Grid, InputAdornment, Link, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import dayjs from 'dayjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColorLegend from '../../components/ColorLegend';
import ConfirmDialog from '../../components/ConfirmDialog';
import Iconify from '../../components/Iconify';
import ModalComponent from '../../components/ModalComponent';
import { getStudyColor, getStudyLabel } from '../../components/StudiesComponent';
import { ActionButton, ThemeButton } from '../../components/button';
import CustomSelect from '../../components/select';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import axiosInstance from '../../utils/axiosInstance';
import { downloadProjectPhotos, downloadSelectedStudy, removeStudies } from './studiesSlice';
import FormDatePicker from '../../components/formComponents/formDatePicker';

const StudiesToolbar = (props) => {
  const {
    handleOpenBackdrop,
    handleCloseBackdrop,
    selected,
    filterName,
    onFilterName,
    searchLable,
    toolbarProps,
    handleClearSelection,
    rows,
  } = props;
  const { singleProjectData, isSingleProjectLoading } = useSelector((state) => state.project);
  const numSelected = selected?.length;
  const { markStudyLoading, studies } = useSelector((state) => state.studies);
  const {
    onCancel,
    onSave,
    placeholder,
    addButtonName,
    handleReviewClicked,
    projectID,
    getStudiesPayload,
    fetchStudies,
    dates,
    setDates,
  } = toolbarProps;
  const [isModalopen, setIsModalOpen] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [dateRange, setDateRange] = useState('5days');
  const [downloadModalType, setDownloadModalType] = useState('study');
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [dateErrorMessage, setDateErrorMessage] = useState('');
  const handleDownloadClicked = (type) => {
    setDownloadModalType(type);
    openModal();
  };
  const [customDateRange, setCustomDateRange] = useState([
    dayjs().subtract(7, 'days'), // Default start date
    dayjs(), // Default end date
  ]);
  const dateRangeOptions = useMemo(
    () => [
      { label: 'Last 5 Days', value: '5days' },
      { label: 'Last Week', value: 'lastWeek' },
      { label: 'Last Quarter', value: 'lastQuarter' },
      { label: 'Last Month', value: 'lastMonth' },
      { label: 'All Studies', value: 'allStudies' },
      { label: 'Custom', value: 'custom' },
    ],
    []
  );
  const dispatch = useDispatch();
  const onConfirmDelete = async () => {
    if (selected && selected?.length > 0) {
      handleOpenBackdrop();
      setIsDeleteModalOpen(false);
      try {
        const payload = getStudiesPayload(selected);
        const response = await axiosInstance({
          url: 'studies/delete',
          method: 'DELETE',
          data: payload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          setIsDeleteModalOpen(false);
          handleClearSelection();
          dispatch(removeStudies(selected));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        setIsDeleteModalOpen(false);
        handleClearSelection();
        handleCloseBackdrop();
      }
    }
  };
  const handleSelectionClick = async (selectedIds, status) => {
    if (!status) {
      console.log('No status provided');
      return;
    }
    if (status === 'delete') {
      setIsDeleteModalOpen(true);
    }
  };
  const downloadProjectStudy = async (studyType) => {
    if (downloadModalType === 'photos') {
      const payload = {
        studyType,
        projectID: get(singleProjectData, '_id'),
        customerID: get(singleProjectData, 'customerID'),
        projectName: get(singleProjectData, 'name'),
        customerName: get(singleProjectData, 'customerName'),
      };
      dispatch(downloadProjectPhotos(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
              })
            );
            closeModal();
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    } else {
      const payload = {
        studyType: '',
        projectID: get(singleProjectData, '_id'),
        customerID: get(singleProjectData, 'customerID'),
        projectName: get(singleProjectData, 'name'),
        customerName: get(singleProjectData, 'customerName'),
      };
      if (studyType === 99) {
        singleProjectData?.studyTypes?.forEach((study) => {
          payload.studyType = study;
          dispatch(downloadSelectedStudy(payload))
            .then((response) => {
              if (response.payload?.success) {
                dispatch(
                  setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'success',
                    snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
                  })
                );
                closeModal();
              } else if (get(response, 'payload.message')) {
                const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
                dispatch(
                  setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMessage: errorMessage,
                  })
                );
              }
            })
            .catch((error) => {
              console.log('error==> ', error);
            });
        });
      } else {
        payload.studyType = studyType;
        dispatch(downloadSelectedStudy(payload))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
                })
              );
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const isRemoveValid = () => {
    // if length 0, if study ras
    if (selected?.length <= 1) {
      return true;
    }
    const firstElement = rows.find((row) => row?._id === selected[0]);
    return selected.every((selectedID, index) => {
      const element = rows.find((row) => row?._id === selectedID);
      return element?.studyType === firstElement?.studyType;
    });
  };
  const downloadMultipleStudies = async () => {
    if (selected && selected?.length > 0 && rows?.length > 0) {
      const row = rows.find((currentRow) => currentRow?._id === selected[0]);
      const payload = {
        studyType: get(row, 'studyType'),
        studyIDs: selected,
        projectID: get(singleProjectData, '_id'),
        customerID: get(singleProjectData, 'customerID'),
        projectName: get(singleProjectData, 'name'),
        customerName: get(singleProjectData, 'customerName'),
      };
      dispatch(downloadSelectedStudy(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
            // fetchStudies();
            handleClearSelection();
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const onChangeDateRange = (event) => {
    const selectedValue = event.target.value;
    setDateRange(selectedValue);

    if (selectedValue === 'custom') return; // Do nothing for custom

    const newDates = (() => {
      switch (selectedValue) {
        case '5days':
          return {
            startDate: dayjs().subtract(5, 'days').startOf('day').toISOString(),
            endDate: dayjs().endOf('day').toISOString(),
          };
        case 'lastWeek':
          return {
            startDate: dayjs().subtract(1, 'week').startOf('week').toISOString(),
            endDate: dayjs().subtract(1, 'week').endOf('week').toISOString(),
          };
        case 'lastMonth':
          return {
            startDate: dayjs().subtract(1, 'month').startOf('month').toISOString(),
            endDate: dayjs().subtract(1, 'month').endOf('month').toISOString(),
          };
        case 'lastQuarter':
          return {
            startDate: dayjs().subtract(1, 'quarter').startOf('quarter').toISOString(),
            endDate: dayjs().subtract(1, 'quarter').endOf('quarter').toISOString(),
          };
        case 'allStudies':
        default:
          return {
            startDate: dayjs('2000-01-01').toISOString(),
            endDate: dayjs().endOf('day').toISOString(),
          };
      }
    })();

    setDates(newDates);
    setFromDate(null);
    setToDate(null);
    setDateErrorMessage('');
  };
  const handleFormDateChange = (newDate) => {
    setFromDate(newDate);
    console.log('newformData', newDate);
    const selectedFrom = dayjs(newDate).toISOString();
    if (toDate) {
      if (dayjs(newDate).isAfter(dayjs(toDate))) {
        setDateErrorMessage(() => 'From date cannot be after To date');
      } else {
        setDates((previous) => ({
          ...previous,
          startDate: selectedFrom,
        }));
        setDateErrorMessage('');
      }
    }
    // const selectedTo = dayjs(toDate);
  };
  const handleToDateChange = (newDate) => {
    setToDate(newDate);
    const selectedTo = dayjs(newDate).toISOString();
    if (fromDate) {
      if (dayjs(newDate).isBefore(dayjs(fromDate))) {
        setDateErrorMessage(() => 'To date cannot be before From date');
      } else {
        setDates((previous) => ({
          ...previous,
          endDate: selectedTo,
        }));
        setDateErrorMessage(() => '');
      }
    }
  };
  return (
    <>
      <ConfirmDialog
        title="Delete"
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        selectedField={selected}
        onConfirm={onConfirmDelete}
        confirmText="Delete"
      >
        <Typography variant="body1">
          Are you sure you want to <b>delete</b> this?
        </Typography>
      </ConfirmDialog>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '300px !important',
            maxHeight: '700px',
          },
        }}
        title={`Download ${downloadModalType === 'study' ? 'Studies' : 'Photos'}`}
        open={isModalopen}
        onClose={closeModal}
        className="download-studies-modal"
      >
        <Stack mb={2} direction="column" gap>
          <Link
            onClick={() => downloadProjectStudy(99)}
            component="button"
            variant="subtitle1"
            sx={{ color: '#0bbbef', textDecorationColor: '#00c0f300' }}
            align="center"
          >
            All Studies
          </Link>
          {singleProjectData?.studyTypes?.length > 0 &&
            singleProjectData?.studyTypes?.map((studyType) => (
              <Link
                onClick={() => downloadProjectStudy(studyType)}
                component="button"
                variant="subtitle1"
                sx={{ color: getStudyColor(studyType), textDecorationColor: '#00c0f300' }}
                align="center"
              >
                {getStudyLabel(studyType)}
              </Link>
            ))}
        </Stack>
        <Stack style={{ display: 'flex', justifyContent: 'center' }} direction="row">
          <ThemeButton variant="outlined" onClick={closeModal} style={{ width: 'fit-content' }}>
            Cancel
          </ThemeButton>
        </Stack>
      </ModalComponent>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 0,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}
        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            <ActionButton
              onClick={downloadMultipleStudies}
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              disabled={isRemoveValid && !isRemoveValid()}
            >
              {`Download ${numSelected > 1 ? 'all' : ''}`}
            </ActionButton>
            <ActionButton
              type="delete"
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="error"
              onClick={() => handleSelectionClick && handleSelectionClick(selected, 'delete', handleClearSelection)}
            >
              {`Remove ${numSelected > 1 ? 'all' : ''}`}
            </ActionButton>
            <ActionButton
              type=""
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              loading={markStudyLoading}
              onClick={() => handleReviewClicked && handleReviewClicked(selected, handleClearSelection)}
            >
              Mark as Reviewed
            </ActionButton>
          </Stack>
        ) : (
          <Grid p={0} container alignItems="center" spacing>
            <Grid item lg={2} xl={2.4} md={4} xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{
                    fontSize: '0.86rem',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || placeholder || 'Search Studies'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={2} xl={1.5} md={4} xs={12}>
              <CustomSelect
                sx={{ maxWidth: '100%' }}
                // label="Sector"
                value={dateRange}
                options={dateRangeOptions}
                onChange={onChangeDateRange}
              />
            </Grid>
            {dateRange === 'custom' && (
              <Grid className="project-datepicker" item lg={2} xl={2.5} md={4} xs={12}>
                <Stack direction="column" gap>
                  <Stack
                    className="custom-datepicker"
                    width="100%"
                    sx={{ '&.custom-datepicker .MuiTextField-root': { minWidth: 'unset !important' }, mt: '4px' }}
                    direction="row"
                  >
                    {/* <Box
                                 className="custom-datepicker"
                                 sx={{
                                   '&.custom-datepicker .MuiTextField-root': { minWidth: 'unset' },
                                   display: 'flex',
                                   flexDirection: 'row',
                                   alignItems: 'center',
                                 }}
                               > */}
                    <FormDatePicker
                      noClerButton
                      mb={0.1}
                      range="left"
                      name="expiryDate"
                      value={fromDate}
                      // onChange={(newDate) => setFromDate(newDate)}
                      onChange={handleFormDateChange}
                      placeholder="From"
                      onClear={() => setFromDate(null)}
                      height="1.8em !important"
                      // formLabel='Subscription End'
                    />
                    <FormDatePicker
                      noClerButton
                      mb={0.1}
                      range="right"
                      name="expiryDate"
                      value={toDate}
                      // onChange={(newDate) => setToDate(newDate)}
                      onChange={handleToDateChange}
                      placeholder="To"
                      onClear={() => setToDate(null)}
                      height="1.8em !important"
                      // formLabel='Subscription End'
                    />
                    {/* </Box> */}
                  </Stack>
                </Stack>
              </Grid>
            )}
            <Grid item lg={2} xl={2} md={4} xs={12}>
              <ColorLegend color="#ffc107a3" label="Rows with duplicate Studies" />
              {dateErrorMessage && <FormHelperText sx={{ color: 'error.main' }}>{dateErrorMessage}</FormHelperText>}
            </Grid>
            <Grid
              justifyContent="flex-end"
              item
              xs={12}
              md={6}
              xl={dateRange !== 'custom' ? 6 : 3.5}
              lg={dateRange !== 'custom' ? 6 : 4}
            >
              <Stack
                gap={3}
                width="100%"
                sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                {studies?.length > 0 && (
                  <>
                    <ThemeButton
                      size="small"
                      onClick={() => handleDownloadClicked('study')}
                      startIcon={<DownloadIcon />}
                      variant="outlined"
                    >
                      Download Studies
                    </ThemeButton>
                    <ThemeButton
                      size="small"
                      onClick={() => handleDownloadClicked('photos')}
                      startIcon={<DownloadIcon />}
                      variant="outlined"
                    >
                      Download Photos
                    </ThemeButton>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

StudiesToolbar.propTypes = {
  selected: PropTypes.array,
  rows: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleClearSelection: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
};

export default StudiesToolbar;
