import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';
// import { projects } from './data';

const getResponseMessage = (successCount, rejectedCount) => {
  if (rejectedCount <= 0) {
    return 'All the roles are successfully updated';
  }
  if (successCount <= 0) {
    return 'Unfortunately, all roles failed to update';
  }
  return `${successCount} roles were successfully edited, while ${rejectedCount} were not accepted`;
};

const initialState = {
  isLoading: true,
  addRoleLoading: false,
  roles: [],
  isRoleDownloading: false,
};
export const getRoles = createAsyncThunk('roles/getRoles', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'roles/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const downloadRole = createAsyncThunk('roles/downloadRole', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'roles/download',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const addRole = createAsyncThunk('roles/addRole', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'roles/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateRole = createAsyncThunk('roles/updateRole', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `roles/update/${data?._id}`,
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const massEditRoles = createAsyncThunk('element/massEditRoles ', async (payload, { rejectWithValue }) => {
  const baseUrl = 'roles/update/';
  let successCount = 0;
  let rejectedCount = 0;
  let responseMessage = '';

  const responses = await Promise.allSettled(
    payload.map((data) => {
      const { _id, ...rest } = data;
      return axiosInstance({
        url: `${baseUrl}${data?._id}`,
        method: 'PATCH',
        data: rest,
      });
    })
  );
  responses.forEach((res) => {
    if (res.status === 'fulfilled') {
      if (res.value?.status === 200) {
        successCount += 1;
      } else {
        rejectedCount += 1; // Increment for non-200 responses
      }
    } else {
      // Handle the rejected promise here
      rejectedCount += 1; // Increment for failed requests
      console.log('Error:', res.reason); // Log the reason for rejection
    }
  });

  responseMessage = getResponseMessage(successCount, rejectedCount);
  return { successCount, rejectedCount, message: responseMessage };
});
export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state, action) => {
      state.isLoading = true;
      state.roles = [];
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload?.data;
      state.isLoading = false;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addRole.pending, (state, action) => {
      state.addRoleLoading = true;
    });
    builder.addCase(addRole.fulfilled, (state, action) => {
      state.addRoleLoading = false;
    });
    builder.addCase(addRole.rejected, (state, action) => {
      state.addRoleLoading = false;
    });
    builder.addCase(updateRole.pending, (state, action) => {
      state.addRoleLoading = true;
    });
    builder.addCase(updateRole.fulfilled, (state, action) => {
      state.addRoleLoading = false;
    });
    builder.addCase(updateRole.rejected, (state, action) => {
      state.addRoleLoading = false;
    });
    builder.addCase(downloadRole.pending, (state, action) => {
      state.isRoleDownloading = true;
    });
    builder.addCase(downloadRole.fulfilled, (state, action) => {
      state.isRoleDownloading = false;
    });
    builder.addCase(downloadRole.rejected, (state, action) => {
      state.isRoleDownloading = false;
    });
  },
});

export default rolesSlice.reducer;
