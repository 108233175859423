/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, DialogActions, Grid, IconButton, Stack, ToggleButtonGroup, Typography } from '@mui/material';
import { get, has, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../components/ModalComponent';
import StudiesComponent from '../../components/StudiesComponent';
import { ThemeButton } from '../../components/button';
import CheckboxGroup from '../../components/checkbox/CheckboxGroup';
import ThemeCheckbox from '../../components/checkbox/themeChekbox';
import FormInput from '../../components/formComponents/FormInput';
import MultiSelect from '../../components/multiSelect';
import CustomSelect from '../../components/select';
import StatusIndicator from '../../components/status';
import ThemeSwitch from '../../components/switch';
import DataTable from '../../components/table';
import { Types } from '../../types';
import { addQuestionScheme } from '../../utils/schema';
import { getElements } from '../projectList/elements/elementsSlice';
import Datetime from '../reminder/Datetime';
import { ThemeToggleButton } from '../reminder/RemindersPage';
import { getTasks } from '../tasksPage/taskSlice';
import { addQuestion, getQuestions, updateQuestion } from './questionsSlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import axiosInstance from '../../utils/axiosInstance';

const showQuestionTypes = {
  element: 'SHOW_ON_TASK_ELEMENTS',
  studyEnd: 'END_OF_STUDY',
  dateTime: 'SHOW_ON_DAYS',
  duration: 'TIME_IN_STUDY',
  timeOfDay: 'TIME_OF_DAY',
};

const QuestionPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  const studyAppConfig = { label: 'label', value: 'value' };
  const { singleProjectData } = useSelector((state) => state.project);
  const studyAppOptions = useMemo(() => {
    const defaultStudyAppOptions = [
      { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
      { label: 'Role Study', value: 2, color: '#784fc2' },
      { label: 'Activity Study', value: 3, color: '#0ba0ef' },
      // { label: 'Predetermined Study', value: 4, color: '#9CC13B' },
    ];
    if (defaultStudyAppOptions && singleProjectData && singleProjectData?.studyTypes) {
      return defaultStudyAppOptions?.filter((option) => singleProjectData?.studyTypes?.includes(option?.value));
    }
    return [];
  }, [singleProjectData]);
  useEffect(() => {
    if (projectID) {
      dispatch(getQuestions({ projectID }));
      dispatch(getTasks({ projectID }));
      dispatch(getElements({ projectID, defaultEntry: false }));
    }
  }, [dispatch, projectID]);
  const { questions, isLoading, isAdding } = useSelector((state) => state?.questions);
  const { elements: projectElements } = useSelector((state) => state.projectElements);
  const { tasks } = useSelector((state) => state.tasks);
  const [delayedLoading, setDelayedLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, 160);

      return () => clearTimeout(timer); // ✅ Return cleanup function
    }
    setDelayedLoading(true);
    return undefined;
  }, [isLoading]);
  const headCells = [
    {
      id: 'name',
      numeric: false,
      // disablePadding: true,
      label: 'Description',
      // titleCase: true,
      // align: 'left',
    },
    {
      id: 'triggerTime',
      numeric: false,
      // disablePadding: true,
      label: 'Trigger Time',
      disableSort: true,
      // titleCase: true,
      // align: 'left',
    },
    {
      id: 'triggerTypeLabel',
      numeric: false,
      // disablePadding: true,
      label: 'Trigger Type',
      titleCase: true,
      // align: 'left',
    },
    {
      id: 'studiesLength',
      disablePadding: false,
      // minWidth: '360px',
      // maxWidth: '360px',
      label: 'Studies',
      // align: 'center',
      renderElement: (cell) => <StudiesComponent pl={0.01} studyTypes={get(cell, 'projectSetting.studyTypes')} />,
    },
    {
      id: 'status',
      numeric: false,
      // disablePadding: true,
      // align: 'left',
      label: 'Active',
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.status') && get(cell, 'projectSetting.status') === 'active' ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="error" />
        ),
    },
    {
      id: 'answerRequired',
      numeric: false,
      // disablePadding: true,
      // align: 'left',
      label: 'Answer Required',
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.answerRequired') && get(cell, 'projectSetting.answerRequired') ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="error" />
        ),
    },
    {
      id: 'isPhoto',
      numeric: false,
      // disablePadding: true,
      // align: 'left',
      label: 'Photo',
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.takePhoto') && get(cell, 'projectSetting.takePhoto') ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="error" />
        ),
    },
    // {
    //   id: 'status',
    //   label: 'Status',
    //   renderElement: (cell) => has(cell, 'projectSetting.status') && <StatusIndicator cell={cell?.projectSetting} />,
    // },
  ];
  const getTriggerTypeLabel = (triggerType) => {
    switch (triggerType) {
      case 'TIME_IN_STUDY':
        return 'Time in study';

      case 'END_OF_STUDY':
        return 'End of study';

      case 'TIME_OF_DAY':
        return 'Time of day';

      case 'SHOW_IN_TASK_ELEMENT':
        return 'Show in task element';

      case 'SHOW_ON_TASK_ELEMENTS':
        return 'Show in task element';

      case 'SHOW_ON_DAYS':
        return 'Show on days';

      default:
        return '';
    }
  };
  const regularQuestions = useMemo(
    () =>
      questions &&
      questions.reduce(
        (acc, question) => {
          const questionObject = {
            ...question,
            triggerTypeLabel: getTriggerTypeLabel(get(question, 'projectSetting.triggerType')),
            status: get(question, 'projectSetting.status'),
            task: get(question, 'projectSetting.taskID', ''),
            elements: get(question, 'projectSetting.elements', []),
            answerRequired: get(question, 'projectSetting.answerRequired', false),
            isPhoto: get(question, 'projectSetting.takePhoto', false),
            triggerTime:
              get(question, 'projectSetting.triggerTime') &&
              `${get(question, 'projectSetting.triggerTime.hours', 0)}h ${get(question, 'projectSetting.triggerTime.minutes', 0)}m`,
          };
          if (question?.projectSetting?.status === 'active') {
            acc.active.push(questionObject);
          } else if (question?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(questionObject);
          }
          acc.default.push(questionObject);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [questions]
  );
  const tasksOptions = useMemo(
    () =>
      tasks?.length > 0
        ? tasks
            ?.filter((taskElement) => taskElement?.projectSetting.status === 'active')
            ?.map((taskElement) => ({ label: taskElement?.name, value: taskElement?._id }))
        : [],
    [tasks]
  );

  const form = useForm({
    defaultValues: {
      name: '',
      questionType: 'text',
      questionOptions: [],
      studyTypes: [],
      triggerType: showQuestionTypes?.studyEnd,
      triggerTime: {
        hours: 0,
        minutes: 0,
      },
      showOnDays: [],
      elements: [],
      taskID: '',
      // optional fields
      isFreeTextBox: false,
      answerRequired: false,
      takePhoto: false,
      numericPads: false,
    },
    resolver: yupResolver(addQuestionScheme),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questionOptions',
  });
  const questionoptions = watch('questionOptions');
  const studyTypesValues = watch('studyTypes');
  const taskIDValue = watch('taskID');
  const elementsOptions = useMemo(() => {
    let options = [];
    let filteredOptions = [];
    options =
      projectElements?.length >= 0 &&
      projectElements
        ?.filter((element) => element?.projectSetting?.status === 'active')
        ?.map((element) => ({ label: element?.name, value: element?._id }));
    if (
      projectElements?.length >= 0 &&
      studyTypesValues?.length > 0 &&
      (studyTypesValues.includes(2) || studyTypesValues.includes(3))
    ) {
      if (taskIDValue && tasks?.length >= 0) {
        const task = tasks.find((field) => field?._id === taskIDValue);
        const taskElements = get(task, 'projectSetting.elements', []);
        filteredOptions = options?.length > 0 ? options.filter((option) => taskElements?.includes(option?.value)) : [];
      }
    } else {
      filteredOptions = options;
    }
    return filteredOptions;
  }, [projectElements, studyTypesValues, taskIDValue, tasks]);

  const [questionRows, setQuestionRows] = useState([]);
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [isModalopen, setisModalopen] = useState(false);
  const [Status, setStatus] = useState(ACTIVE);
  const [rowToEdit, setRowToEdit] = useState('');
  const [disabledStudyOptions, setDisabledStudyOptions] = useState([]);
  const triggerType = watch('triggerType');
  useEffect(() => {
    if (isModalopen && questionoptions && questionoptions?.length <= 0) {
      append('');
    }
  }, [append, isModalopen, questionoptions]);
  useEffect(() => {
    if (studyTypesValues.includes(1)) {
      if (triggerType === showQuestionTypes.duration || triggerType === showQuestionTypes.studyEnd) {
        setDisabledStudyOptions([]);
      } else {
        setDisabledStudyOptions([2, 3]);
      }
    } else if (studyTypesValues.includes(2) || studyTypesValues.includes(3)) {
      if (triggerType === showQuestionTypes.duration || triggerType === showQuestionTypes.studyEnd) {
        setDisabledStudyOptions([]);
      } else {
        setDisabledStudyOptions([1]);
      }
    } else {
      setDisabledStudyOptions([]);
    }
  }, [setValue, studyTypesValues, triggerType]);

  useEffect(() => {
    if (regularQuestions) {
      if (Status === DEFAULT) {
        setQuestionRows(regularQuestions?.default);
      } else if (Status === ARCHIVED) {
        setQuestionRows(regularQuestions?.archive);
      } else if (Status === ACTIVE) {
        setQuestionRows(regularQuestions?.active);
      }
    } else {
      setQuestionRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularQuestions]);
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setQuestionRows(regularQuestions.default);
      } else if (value === ACTIVE) {
        setQuestionRows(regularQuestions.active);
      } else if (value === ARCHIVED) {
        setQuestionRows(regularQuestions.archive);
      }
    }
  };
  const questionTypeOptions = [
    { label: 'Text', value: 'text' },
    { label: 'Radio', value: 'radio' },
    { label: 'Checkbox', value: 'checkbox' },
    { label: 'Number', value: 'number' },
  ];
  const questionType = watch('questionType');
  useEffect(() => {
    if (questionType === 'number') {
      setValue('isFreeTextBox', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionType]);
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    // reset({ permission: '' });
    // setShowPassword(false);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      const questionToEdit = questions.find((area) => area?._id === row?._id);
      setValue('name', get(questionToEdit, 'name', ''));
      setValue('studyTypes', get(questionToEdit, 'projectSetting.studyTypes', []));
      setValue('elements', get(questionToEdit, 'projectSetting.elements', []));
      setValue('taskID', get(questionToEdit, 'projectSetting.taskID', ''));
      setValue('repeat', get(questionToEdit, 'projectSetting.repeat', false));
      setValue('takePhoto', get(questionToEdit, 'projectSetting.takePhoto', false));
      setValue('answerRequired', get(questionToEdit, 'projectSetting.answerRequired', false));
      setValue('isFreeTextBox', get(questionToEdit, 'projectSetting.isFreeTextBox', false));
      setValue('numericPads', get(questionToEdit, 'projectSetting.numericPads', false));
      setValue('questionType', get(questionToEdit, 'projectSetting.questionType', ''));
      setValue('showOnDays', get(questionToEdit, 'projectSetting.showOnDays', []));
      setValue('questionOptions', get(questionToEdit, 'projectSetting.questionOptions', []));
      setValue('triggerTime', get(questionToEdit, 'projectSetting.triggerTime', { hours: 0, minutes: 0 }));
      setValue('triggerType', get(questionToEdit, 'projectSetting.triggerType', ''));
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const hideNestedFunc = (row) => {
    const isELement = get(row, 'projectSetting.triggerType') !== 'SHOW_ON_TASK_ELEMENTS';
    return isELement;
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `questions/delete/${row?._id}`,
          method: 'DELETE',
          data: { projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getQuestions({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'questions/update/status',
          method: 'PATCH',
          data: { questionIDs: [row?._id], status, projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Question`,
            })
          );
          dispatch(getQuestions({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onSubmit = (values) => {
    if (projectID && customerID && values && values?.triggerType) {
      const { triggerTime, showOnDays, elements, taskID, questionOptions, ...rest } = values;
      const payload = { ...rest };
      if (questionOptions && questionOptions?.length > 0) {
        payload.questionOptions = questionOptions;
      }
      if (values?.triggerType === showQuestionTypes?.element) {
        payload.elements = elements;
        payload.triggerTime = { hours: 0, minutes: 0 };
        if (!values?.studyTypes?.includes(1)) {
          payload.taskID = taskID;
        }
      } else if (values?.triggerType === showQuestionTypes?.dateTime) {
        payload.triggerTime = triggerTime;
        payload.showOnDays = showOnDays;
      } else {
        payload.triggerTime = triggerTime;
      }
      if (rowToEdit) {
        dispatch(updateQuestion({ ...payload, customerID, projectID, _id: rowToEdit }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Question Updated Successfully'),
                })
              );
              dispatch(getQuestions({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      } else {
        dispatch(addQuestion({ ...payload, customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Question Added Successfully'),
                })
              );
              dispatch(getQuestions({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const onValueChange = (onChange, value, item) => {
    const newArray = [...value];
    if (newArray.length > 0) {
      const index = newArray.findIndex((x) => x === item);

      // If theres no match add item to the array
      if (index === -1) {
        newArray.push(item);
      } else {
        newArray.splice(index, 1);
      }
    } else {
      if (item === 2 || item === 3) {
        setValue('elements', []);
      } else {
        setValue('taskID', '');
      }
      newArray.push(item);
    }

    onChange(newArray);
  };
  // const isNumeric = () => getValues('numericPads') === 'number';
  // const handleNumericChange = () => {
  //   if (getValues('numericPads') === 'number') {
  //     setValue('numericPads', 'text');
  //   } else {
  //     setValue('numericPads', 'number');
  //   }
  // };
  const renderHelperComponents = () => {
    switch (watch('triggerType')) {
      case showQuestionTypes?.dateTime:
        return (
          <Datetime
            dateError={errors?.showOnDays || null}
            setValue={setValue}
            label="Time of day"
            register={register}
            control={control}
          />
        );
      case showQuestionTypes?.timeOfDay:
        return <Datetime setValue={setValue} disableDate label="Trigger Time" register={register} control={control} />;
      case showQuestionTypes?.duration:
        return <Datetime setValue={setValue} disableDate label="Time from study start" register={register} control={control} />;
      case showQuestionTypes?.element:
        return (
          <Stack gap={1}>
            {(studyTypesValues.includes(2) || studyTypesValues.includes(3)) && (
              <Box>
                {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                <label style={{ fontSize: '0.8600rem' }}>Task</label>
                <CustomSelect
                  name="taskID"
                  control={control}
                  options={tasksOptions}
                  label="Select Task"
                  // width="95%"
                  error={!isEmpty(get(errors, 'taskID'))}
                  helperText={has(errors, 'taskID.message') && get(errors, 'taskID.message')}
                />
              </Box>
            )}
            <MultiSelect
              name="elements"
              multiple
              getOptionLabel={(option) => option.label ?? option}
              control={control}
              options={elementsOptions}
              label="Elements"
              placeholder={!watch('elements')?.length && 'Select Elements'}
              error={!isEmpty(get(errors, 'elements'))}
              helperText={has(errors, 'elements.message') && get(errors, 'elements.message')}
            />
          </Stack>
        );
      default:
        return null;
    }
  };
  const getPayload = (selected, status, id) => ({ questionIDs: selected, status, projectID: id });
  const fetchData = () => {
    dispatch(getQuestions({ projectID }));
  };
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Question',
    openModal,
    addButtonName: 'Create Question',
    getPayload,
    fetchData,
  };
  return (
    <Box>
      <ModalComponent
        persist
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: '100%',
            // maxWidth: '600px !important',
            // maxHeight: '700px',
          },
        }}
        title={rowToEdit ? 'Edit Question' : 'Add Question'}
        // title="Add Area"
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid sx={{ minHeight: 270 }} container justifyContent="space-between" spacing={6}>
            {/* <Grid item lg={5.8} sm={12} xs={12} md={12}>
              <FormInput
                label="Description"
                placeholder="What do you need?"
                InputProps={{
                  ...register('name'),
                }}
                error={!!errors?.name}
                helperText={errors?.name && errors?.name?.message}
              />
              <Stack justifyContent="space-between" direction={{ sm: 'column', md: 'row' }}>
                <CheckboxGroup
                  control={control}
                  label="Study Types"
                  name="studyTypes"
                  // onValueChange={onValueChange}
                  options={studyAppOptions}
                  fontWeight="600"
                  config={studyAppConfig}
                  disableOptions={disabledStudyOptions}
                />
                <ThemeSwitch label="Take photo" control={control} name="takePhoto" width="100%" />
              </Stack>
            </Grid> */}
            <Grid item lg={5.8} sm={12} xs={12} md={12}>
              <FormInput
                error={!!get(errors, 'name')}
                helperText={!!get(errors, 'name') && get(errors, 'name.message')}
                InputProps={{ ...register('name') }}
                label="Question"
                placeholder="Write your question"
              />
              <label style={{ fontSize: '0.8600rem' }}>Question Type</label>
              <CustomSelect
                // InputProps={{ ...register('questionType') }}
                name="questionType"
                control={control}
                defaultValue="text"
                sx={{ mb: 2 }}
                options={questionTypeOptions}
                error={!isEmpty(get(errors, 'questionType'))}
                helperText={has(errors, 'questionType.message') && get(errors, 'questionType.message')}
              />
              {(watch('questionType') === 'radio' ||
                watch('questionType') === 'number' ||
                watch('questionType') === 'checkbox') && (
                <Box sx={{ bgcolor: '#efefef', padding: 2, borderRadius: '15px', mb: 2 }}>
                  {fields?.length > 0 &&
                    fields?.map((question, index) => {
                      const questionProps = register(`questionOptions.${index}`);
                      return (
                        <Stack mb={2} spacing={2}>
                          <Typography variant="body2">Option {index + 1}</Typography>
                          <FormInput
                            bgcolor="#fff"
                            onChange={questionProps.onChange}
                            inputRef={questionProps.ref}
                            name={questionProps.name}
                            key={question.id}
                            placeholder="placeholder"
                            InputProps={{
                              endAdornment: fields?.length > 1 && (
                                <IconButton onClick={() => remove(index)}>
                                  <RemoveCircleIcon sx={{ color: 'error.main' }} />
                                </IconButton>
                              ),
                            }}
                            error={!!get(errors, `questionOptions.${index}.message`)}
                            helperText={
                              !!get(errors, `questionOptions.${index}.message`) && get(errors, `questionOptions.${index}.message`)
                            }
                          />
                        </Stack>
                      );
                    })}
                  <Box sx={{ textAlign: 'right', paddingRight: '14px' }}>
                    <IconButton onClick={() => append('')}>
                      <AddCircleIcon sx={{ color: 'primary.dark' }} />
                    </IconButton>
                  </Box>

                  {/* {watch('questionType') !== 'number' && ( */}
                  <ThemeCheckbox
                    checkboxProps={{ disabled: watch('questionType') === 'number', fontSize: '2rem' }}
                    control={control}
                    name="isFreeTextBox"
                    label="Free Text Box"
                  />
                  {/* )} */}
                </Box>
              )}
            </Grid>
            <Grid item lg={5.8} sm={12} xs={12} md={12}>
              <Stack direction={{ sm: 'row', xs: 'column' }} justifyContent="space-between">
                <CheckboxGroup
                  control={control}
                  label="Study Types"
                  name="studyTypes"
                  onValueChange={onValueChange}
                  options={studyAppOptions}
                  fontWeight="600"
                  config={studyAppConfig}
                  disableOptions={disabledStudyOptions}
                />
                <Stack direction="column">
                  <ThemeSwitch label="Answer Required" control={control} name="answerRequired" />
                  <ThemeSwitch label="Take Photo" control={control} name="takePhoto" />
                  <ThemeSwitch label="Numeric pads" control={control} name="numericPads" />
                </Stack>
              </Stack>
              <Typography sx={{ mt: 0, fontWeight: 600 }} variant="body1">
                Show Question On
              </Typography>
              <Controller
                control={control}
                name="triggerType"
                render={({ field }) => {
                  // console.log('field--> ', field.onChange);
                  const { onChange, ...rest } = field;
                  return (
                    <ToggleButtonGroup
                      sx={{ width: '100%', flexWrap: 'wrap', mb: 2 }}
                      color="primary"
                      exclusive
                      ref={field.ref}
                      {...rest}
                      onChange={(event) => {
                        if (
                          event.target.value === showQuestionTypes.element ||
                          event.target.value === showQuestionTypes.dateTime
                        ) {
                          if (studyTypesValues.includes(1) && (studyTypesValues.includes(2) || studyTypesValues.includes(3))) {
                            setValue(
                              'studyTypes',
                              studyTypesValues.filter((val) => val === 1)
                            );
                          }
                        }
                        field.onChange(event);
                      }}
                      value={field.value}
                    >
                      <ThemeToggleButton
                        sx={{ mx: 2, my: 1, ml: 0, borderRadius: '25px !important', px: 2 }}
                        value={showQuestionTypes.studyEnd}
                      >
                        Study End
                      </ThemeToggleButton>
                      <ThemeToggleButton
                        sx={{ mx: 2, my: 1, borderRadius: '25px !important' }}
                        value={showQuestionTypes.duration}
                      >
                        Duration
                      </ThemeToggleButton>
                      <ThemeToggleButton sx={{ mx: 2, my: 1, borderRadius: '25px !important' }} value={showQuestionTypes.element}>
                        Element
                      </ThemeToggleButton>
                      <ThemeToggleButton
                        sx={{
                          mx: 2,
                          my: 1,
                          mr: 0,
                          borderRadius: '25px !important',
                        }}
                        value={showQuestionTypes.dateTime}
                      >
                        Date/Time
                      </ThemeToggleButton>
                    </ToggleButtonGroup>
                  );
                }}
              />
              {errors?.triggerType && errors?.triggerType?.message && (
                <Typography variant="caption" color="error">
                  {errors?.triggerType?.message}
                </Typography>
              )}
              {renderHelperComponents()}
            </Grid>
          </Grid>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isAdding} type="submit" variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={delayedLoading}
        headCells={headCells}
        rows={questionRows}
        searchLable="Search Areas"
        onRowStatusChange={onRowStatusChange}
        onEditClick={onEditClick}
        nestedRowKey={Types.nestedRowTypes.REMINDERS}
        // handleSelectionClick={handleSelectionClick}
        filterKeys={['name', 'position']}
        customToolbar={Types.toolbarTypes.QUESTION}
        statusUpdateUrl="questions/update/status"
        maxHeight={453}
        stickyHeader
        toolbarProps={toolbarProps}
        hideCompactViewButton
        onRowDelete={onDelete}
        hideNestedFunc={hideNestedFunc}
        defaultRowsPerPage={10}
      />
      <DevTool control={control} />
    </Box>
  );
};

export default QuestionPage;
QuestionPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
