import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';

const ColorLegend = ({ color, label }) => {
  let a;
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Box sx={{ bgcolor: color || '', p: 1, borderRadius: '50%' }} />
      <Typography variant="body2">{label}</Typography>
    </Stack>
  );
};

ColorLegend.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

export default ColorLegend;
