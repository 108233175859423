import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './sidebar';

const DashboardLayout = () => {
  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    // minHeight: '100%',
    paddingTop: 16,
    // paddingBottom: theme.spacing(10),
    // paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      // paddingTop: APP_BAR_DESKTOP + 24,
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
    },
  }));
  // return (
  //   <StyledRoot>
  //     <Header />
  //     <Sidebar />
  //     <Main >
  //       <Outlet />
  //     </Main>
  //   </StyledRoot>
  // )
  return (
    <Box display="flex" height="100vh" minHeight="100%" overflow="hidden" backgroundColor="#E5E5E5">
      {/* <Header /> */}
      <Sidebar />
      <Main>
        <Outlet />
      </Main>
    </Box>
  );
};

export default DashboardLayout;
