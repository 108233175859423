import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import EmptyIcon from '../../assets/images/empty-folder.png';
import { toggleDownload } from '../../layout/dashboard/dashboardSlice';
import StatusCard from './StatusCard';
import { getDownloadLinks } from '../studiesPage/studiesSlice';

const DownloadComponent = (props) => {
  const dispatch = useDispatch();
  const { downloadLinks } = useSelector((state) => state.studies);
  const location = useLocation();
  const params = useParams();
  // Define the pattern for project route
  const isProjectRoute = location.pathname.startsWith('/project/');

  const isAllComplete = downloadLinks.every((link) => link.status === 'COMPLETE');

  useEffect(() => {
    // Define the API call function
    const fetchDownloadLinks = () => {
      if (isProjectRoute) {
        dispatch(getDownloadLinks({ projectID: params.id }));
      } else {
        dispatch(getDownloadLinks({ isAdmin: true }));
      }
    };

    fetchDownloadLinks();

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      if (!isAllComplete) {
        fetchDownloadLinks();
      }
    }, 10000);

    // Clear interval when component unmounts or when all items are complete
    return () => clearInterval(interval);
  }, [dispatch, isProjectRoute, params?.id, isAllComplete]);

  if (downloadLinks?.length <= 0) {
    return (
      <Stack alignItems="flex-start" width="100%" direction="column" p={0} height="100%">
        <IconButton onClick={() => dispatch(toggleDownload())}>
          <CloseIcon />
        </IconButton>
        <Stack my={2} width="100%" alignItems="center" direction="column">
          <img className="empty-folder" src={EmptyIcon} alt="empty" />
          <Typography variant="h5">No download links found</Typography>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack alignItems="flex-start" width="100%" direction="column" p={0} height="100%">
      <IconButton onClick={() => dispatch(toggleDownload())}>
        <CloseIcon />
      </IconButton>
      <Stack width="100%" direction="column" p={2}>
        {downloadLinks?.map((response) => (
          <StatusCard
            status={get(response, 'status')}
            fileName={get(response, 'filename')}
            filekey={get(response, 'filekey')}
            downloadTime={get(response, 'downloadTime')}
            extension={get(response, 'extension')}
            category={get(response, 'category')}
            downloadLinkID={get(response, '_id')}
          />
        ))}
      </Stack>
    </Stack>
  );
};

DownloadComponent.propTypes = {};

export default DownloadComponent;
