/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import axiosInstance from '../../utils/axiosInstance';

const cookies = new Cookies();
export const userLogin = createAsyncThunk('auth/userLogin', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      // url: 'users/authenticate',
      url: 'users/login',
      method: 'POST',
      data: credentials,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const otpVerification = createAsyncThunk('auth/otpVerification', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      // url: 'users/authenticate',
      url: 'users/otp/verify',
      method: 'POST',
      data: credentials,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const resetPassword = createAsyncThunk('auth/resetPassword', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      // url: 'users/authenticate',
      url: 'users/password/reset/request',
      method: 'POST',
      data: credentials,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updatePassword = createAsyncThunk('auth/updatePassword', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      // url: 'users/authenticate',
      url: 'users/password/update',
      method: 'POST',
      data: credentials,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const renewOtp = createAsyncThunk('auth/renewOtp', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      // url: 'users/authenticate',
      url: 'users/otp/renew',
      method: 'POST',
      data: credentials,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const fetchCurrentUser = createAsyncThunk('auth/fetchCurrentUser', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      // url: 'users/authenticate',
      url: `users/get/${payload?.id}`,
      method: 'GET',
    });
    const data = await response.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

const initialState = {
  isLoading: false,
  userInfo: null, // for user object
  userToken: null, // for storing the JWT
  error: null,
  userName: '',
  errorMessage: null,
  success: false, // for monitoring the registration process.
  role: null,
  isOtpLoading: false,
  otpError: null,
  otpErrorMessage: '',
  otpSuccess: false,
  isUpdatePasswordLoading: false,
  isResetPasswordLoading: false,
  userDetails: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logoutUser: (state, action) => {
      // state.success = false;
      state.userName = '';
      state.userInfo = null;
      state.userToken = null;
      state.role = null;
      state.userDetails = null;
    },
    getRole: (state, action) => {
      const jwtToken = cookies.get('token');
      const roles = jwtToken && jwtDecode(jwtToken);
      state.role = roles?.role;
      state.userName = roles?.user && roles?.user?.name && roles?.user?.lastname && `${roles.user.name} ${roles.user.lastname}`;
      state.userDetails = { ...roles?.user, _id: roles?._id };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      // const role = String(Role.role)
      const token = action.payload?.token;
      const roles = token && jwtDecode(token);
      state.userName = roles?.user && roles?.user?.name && roles?.user?.lastname && `${roles.user.name} ${roles.user.lastname}`;
      state.role = roles?.role;
      state.isLoading = false;
      state.userInfo = action.payload;
      state.errorMessage = '';
      state.userDetails = roles?.user;
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.userDetails = action?.payload?.data;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      // state.error = action.error.message;
    });
    builder.addCase(otpVerification.pending, (state, action) => {
      state.isOtpLoading = true;
      state.otpError = false;
      state.otpErrorMessage = '';
    });
    builder.addCase(otpVerification.rejected, (state, action) => {
      state.isOtpLoading = false;
      state.otpError = true;
      state.otpErrorMessage = action.payload?.message;
    });
    builder.addCase(otpVerification.fulfilled, (state, action) => {
      const token = action.payload?.token;
      const roles = token && jwtDecode(token);
      state.userName = roles?.user && roles?.user?.name && roles?.user?.lastname && `${roles.user.name} ${roles.user.lastname}`;
      state.role = roles?.role;
      state.isOtpLoading = false;
      state.otpError = false;
      state.otpErrorMessage = '';
      state.otpSuccess = true;
    });
    builder.addCase(renewOtp.rejected, (state, action) => {
      if (action?.payload?.success === false) {
        state.otpError = true;
        state.otpErrorMessage = action.payload?.message;
        state.isOtpLoading = false;
      }
    });
    builder.addCase(renewOtp.fulfilled, (state, action) => {
      state.otpError = false;
      state.otpErrorMessage = '';
      state.isOtpLoading = false;
    });
    builder.addCase(renewOtp.pending, (state, action) => {
      state.otpError = false;
      state.otpErrorMessage = '';
      state.isOtpLoading = true;
    });
    builder.addCase(updatePassword.pending, (state, action) => {
      state.isUpdatePasswordLoading = true;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isUpdatePasswordLoading = false;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isUpdatePasswordLoading = false;
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.isResetPasswordLoading = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isResetPasswordLoading = false;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isResetPasswordLoading = false;
    });
  },
});

export default loginSlice.reducer;
export const { logoutUser, getRole } = loginSlice.actions;
