import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  hoursMappings: [],
  isLoading: false,
  isUpdating: false,
};
export const getHoursMapping = createAsyncThunk('hoursMapping/getHours', async (role, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'hours/get',
      method: 'GET',
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const updateHoursMapping = createAsyncThunk('hoursMapping/updateHours', async (payload, { rejectWithValue }) => {
  const { _id, ...rest } = payload;
  if (_id) {
    try {
      const response = await axiosInstance({
        url: `hours/update/${_id}`,
        method: 'PATCH',
        data: rest,
      });
      const data = (await response?.data) || null;
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response?.data);
    }
  }
  return null;
});
const getResponseMessage = (successCount, rejectedCount) => {
  if (rejectedCount <= 0) {
    return 'All the hours are successfully updated';
  }
  if (successCount <= 0) {
    return 'Unfortunately, all hours failed to update';
  }
  return `${successCount} hours were successfully edited, while ${rejectedCount} were not accepted`;
};
export const massEditHours = createAsyncThunk('hours/massEditHours ', async (payload, { rejectWithValue }) => {
  const baseUrl = 'hours/update/';
  let successCount = 0;
  let rejectedCount = 0;
  let responseMessage = '';

  const responses = await Promise.allSettled(
    payload.map((data) => {
      const { _id, ...rest } = data;
      return axiosInstance({
        url: `${baseUrl}${data?._id}`,
        method: 'PATCH',
        data: rest,
      });
    })
  );

  responses.forEach((res) => {
    if (res.status === 'fulfilled') {
      if (res.value?.status === 200) {
        successCount += 1;
      } else {
        rejectedCount += 1; // Increment for non-200 responses
      }
    } else {
      // Handle the rejected promise here
      rejectedCount += 1; // Increment for failed requests
      console.log('Error:', res.reason); // Log the reason for rejection
    }
  });

  responseMessage = getResponseMessage(successCount, rejectedCount);
  return { successCount, rejectedCount, message: responseMessage };
});
const hoursMappingSlice = createSlice({
  name: 'hoursMapping',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getHoursMapping.pending, (state, actions) => {
      state.isLoading = true;
    });
    builder.addCase(getHoursMapping.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hoursMappings = action.payload.data;
    });
    builder.addCase(getHoursMapping.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateHoursMapping.pending, (state, actions) => {
      state.isUpdating = true;
    });
    builder.addCase(updateHoursMapping.fulfilled, (state, action) => {
      state.isUpdating = false;
    });
    builder.addCase(updateHoursMapping.rejected, (state, action) => {
      state.isUpdating = false;
    });
  },
});

export default hoursMappingSlice.reducer;
