import { Box, Button, Container, FormLabel, Stack, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import PropTypes from 'prop-types';
import { ThemeButton } from './button';

const FileUploadComponent = ({ image, setImage, responseImage, setResponseImage, isImageChanged, setIsImageChanged }) => {
  const removePic = () => {
    setIsImageChanged(true);
    setImage('');
    document.getElementById('profilePic').value = '';
  };
  const imageSrc = useMemo(() => {
    if (isImageChanged && image) {
      return URL.createObjectURL(image);
    }
    if (responseImage && !isImageChanged) {
      return responseImage;
    }
    return '';
  }, [image, isImageChanged, responseImage]);
  return (
    <Container sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          width: '74%',
          border: '1px dashed #c7c8ca',
          bgcolor: '#f6f6f6',
          borderRadius: '10px',
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
          padding: '40px 0px',
          alignItems: 'center',
          margin: 'auto',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            border: '1px dashed #c7c8ca',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 150,
            width: 150,
          }}
        >
          {/* {image ? (
            <img className="user-profile" alt="logo" src={image && URL.createObjectURL(image)} />
          ) : (
            <UploadFileOutlinedIcon fontSize="large" sx={{ opacity: 0.4 }} />
          )} */}
          {imageSrc ? (
            <img
              className="user-profile"
              alt="logo"
              src={imageSrc}
              // style={{ width: '100%', borderRadius: '8px', objectFit: 'cover' }}
            />
          ) : (
            <UploadFileOutlinedIcon fontSize="large" sx={{ opacity: 0.4 }} />
          )}
        </Box>
        <TextField
          sx={{ width: '100%', display: 'none' }}
          id="profilePic"
          name="profile"
          // label="Profile Image"
          onChange={(e) => {
            // setImage(e.target.files[0])
            setIsImageChanged(true);
            setImage(e.target.files[0]);
            const fileReader = new FileReader();
            if (e.target.files[0]) {
              fileReader.readAsDataURL(e.target.files[0]);
            }
            fileReader.onload = (event) => {
              // setImage(event.target.result);
            };
          }}
          type="file"
        />
      </Box>
      <Stack direction="row" justifyContent="center" gap={1} pt={2} alignItems="center">
        <ThemeButton sx={{ minWidth: 80 }}>
          <FormLabel
            sx={{
              color: 'inherit',
              font: 'inherit',
              minWidth: 80,
              minHeight: '100%',
              // py: 0.8,
              fontWeight: 'inherit',
              borderRadius: 'inherit',
              cursor: 'pointer',
            }}
            htmlFor="profilePic"
          >
            {/* {image ? 'Change' : 'Browse Your Files'} */}
            {imageSrc ? 'Change' : 'Browse Your Files'}
          </FormLabel>
          {/* <label htmlFor='profilePic' >
                                    {formik.values.profile ? "Change" : "Add"}
                                </label> */}
        </ThemeButton>
        {/* {image && (
          <ThemeButton sx={{ minWidth: 80 }} color="error" onClick={removePic}>
            Remove
          </ThemeButton>
        )} */}
        {imageSrc && (
          <ThemeButton sx={{ minWidth: 80 }} color="error" onClick={removePic}>
            Remove
          </ThemeButton>
        )}
      </Stack>
      {/* <ThemeButton>Browse Your Files</ThemeButton> */}
    </Container>
  );
};

export default FileUploadComponent;

FileUploadComponent.propTypes = {
  image: PropTypes.any,
  setImage: PropTypes.func,
  responseImage: PropTypes.string,
  setResponseImage: PropTypes.func,
  isImageChanged: PropTypes.bool,
  setIsImageChanged: PropTypes.func,
};
