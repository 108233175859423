/* eslint-disable no-extra-boolean-cast */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Avatar, Box, Container, Stack, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { get, isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThemeButton } from '../../components/button';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../components/status';
import DataTable from '../../components/table';
import { Types } from '../../types';
import { APP_ROUTER_BASE_URL } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { getIndustries } from '../industry/industrySlice';
import { getSectors } from '../sector/sectorsSlice';
import { getCustomers } from './customerSlice';

const CustomerListPage = () => {
  const { isLoading, customers } = useSelector((state) => state.customer);
  const { industries } = useSelector((state) => state.industry);
  const { sectors } = useSelector((state) => state.sectors);
  const dispatch = useDispatch();
  // dispatch(getCategories({ defaultEntry: true }));
  const renderSubscription = (expiryDate) => {
    if (expiryDate) {
      if (expiryDate > 0) {
        return (
          <Tooltip title={`${expiryDate} days left`}>
            <CheckCircleOutlineOutlinedIcon sx={{ ml: 3 }} fontSize="small" color="success" />
          </Tooltip>
        );
      }
      return (
        <Tooltip title="Subscription is expired">
          <RemoveCircleOutlineIcon sx={{ ml: 3 }} fontSize="small" color="error" />
        </Tooltip>
      );
    }
    return null;
  };
  const headCells = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      minWidth: 200,
      renderElement: (cell) => (
        <Stack direction="row" alignItems="center" gap="16px">
          <Avatar
            variant="square"
            sx={{ borderRadius: '8px', width: 70, height: 70, bgcolor: 'transparent', border: '0.5px solid #c7c7c7' }}
            // src={`https://server106.absolutebyte.co.uk/${cell?.image}`}
          >
            <img
              src={cell?.image ? `/${cell.image}` : '/assets/icons/placeholder_customer.jpg'}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = '/assets/icons/placeholder_customer.jpg';
              }}
              alt="profile"
              style={{ width: '65px', borderRadius: '8px', objectFit: 'cover' }}
            />
          </Avatar>
          <Typography>{cell?.name}</Typography>
        </Stack>
      ),
      titleCase: true,
    },
    {
      id: 'industryName',
      label: 'Industry',
      align: 'left',
      minWidth: 160,
      titleCase: true,
    },
    {
      id: 'sectorName',
      label: 'Sector',
      align: 'left',
      minWidth: 250,
      titleCase: true,
    },

    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
      width: '10%',
    },
    {
      id: 'subscriptionRemaining',
      label: 'Subscription',
      align: 'left',
      minWidth: 100,
      renderElement: (cell) => !isUndefined(cell, 'subscriptionRemaining') && renderSubscription(cell?.subscriptionRemaining),
    },
  ];
  const form = useForm({
    defaultValues: {
      name: '',
    },
    // resolver: yupResolver(addCategorySchema),
  });
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [customerRows, setCustomerRows] = useState([]);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const regularCustomers = useMemo(
    () =>
      !isEmpty(customers) &&
      customers.reduce(
        (acc, customer) => {
          const currentDate = dayjs();
          const targetDate = dayjs(customer.expiryDate);
          const daysDifference = targetDate.diff(currentDate, 'day');
          const fullCustomer = {
            ...customer,
            industryName: industries?.length && industries.find((industry) => industry?._id === customer?.industryID)?.name,
            sectorName: sectors?.length && sectors.find((sector) => sector?._id === customer?.sectorID)?.name,
            subscriptionRemaining: daysDifference,
          };
          if (customer?.status === 'active') {
            acc.active.push(fullCustomer);
          } else if (customer?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(fullCustomer);
          }
          acc.default.push(fullCustomer);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [customers, industries, sectors]
  );

  useEffect(() => {
    dispatch(getCustomers());
    dispatch(getIndustries()).then((response) => {
      if (get(response, 'payload.success')) {
        if (!!response?.payload?.data?.length) {
          const options = response.payload.data
            ?.filter((currentIndustry) => currentIndustry?.status === 'active')
            ?.map((industry) => ({ label: industry?.name, value: industry?._id }));
          setIndustryOptions([{ label: 'All Industries', value: '' }, ...options]);
        }
      }
    });
    dispatch(getSectors()).then((response) => {
      setSectorOptions([{ label: 'All Sectors', value: '' }]);
      // if (get(response, 'payload.success')) {
      //   if (!!response?.payload?.data?.length) {
      //     const options = response.payload.data?.map((sector) => ({ label: sector?.name, value: sector?._id }));
      //     setSectorOptions([{ label: 'All Sectors', value: '' }, ...options]);
      //   }
      // }
    });
  }, [dispatch]);
  useEffect(() => {
    if (regularCustomers) {
      let filteredRows;
      if (regularCustomers && Status === DEFAULT) {
        filteredRows = regularCustomers.default;
      } else if (regularCustomers && Status === ACTIVE) {
        filteredRows = regularCustomers.active;
      } else if (regularCustomers && Status === ARCHIVED) {
        filteredRows = regularCustomers.archive;
      }
      if (selectedIndustry) {
        if (selectedSector) {
          if (!!filteredRows?.length) {
            const filteredCustomers = filteredRows.filter(
              (customer) => customer?.sectorID === selectedSector && customer?.industryID === selectedIndustry
            );
            setCustomerRows(filteredCustomers);
          }
        } else if (!!filteredRows?.length) {
          const filteredCustomers = filteredRows.filter((customer) => customer?.industryID === selectedIndustry);
          setCustomerRows(filteredCustomers);
        }
      } else if (selectedSector) {
        const filteredCustomers = filteredRows.filter((customer) => customer?.sectorID === selectedSector);
        setCustomerRows(filteredCustomers);
      } else {
        setCustomerRows(filteredRows);
      }
    }
  }, [ACTIVE, ARCHIVED, DEFAULT, Status, regularCustomers, selectedIndustry, selectedSector]);

  const onSectorChange = (event) => {
    const sectorID = event.target.value;
    setSelectedSector(sectorID);
  };
  const onIndustryChange = (event) => {
    const industryID = event.target.value;
    setSelectedIndustry(industryID);
    if (selectedSector) {
      setSelectedSector('');
    }
    if (!!sectors.length) {
      const options = sectors.reduce((acc, sector) => {
        if (sector?.industryID === industryID && sector?.status === 'active') {
          acc.push({ label: sector?.name, value: sector?._id });
        }
        return acc;
      }, []);
      setSectorOptions([{ label: 'All Sectors', value: '' }, ...options]);
    }
  };

  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
    }
  };

  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Customer',
    setSelectedIndustry,
    setSelectedSector,
    selectedIndustry,
    selectedSector,
    onSectorChange,
    onIndustryChange,
    sectorOptions,
    industryOptions,
  };
  const navigate = useNavigate();
  const createCutomer = () => {
    navigate(`${APP_ROUTER_BASE_URL}customer/create`);
  };

  const onEditClick = async (event, row) => {
    if (row?._id) {
      navigate(`${APP_ROUTER_BASE_URL}customer/edit/${row?._id}`);
    }
  };
  const handleRowClick = (event, row) => {
    event.stopPropagation();
    if (row?._id) {
      navigate(`${APP_ROUTER_BASE_URL}customer/view/${row?._id}`);
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'customers/update/status',
          method: 'PATCH',
          data: { customerIDs: [row?._id], status },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Customer`,
            })
          );
          dispatch(getCustomers());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `customers/delete/${row?._id}`,
          method: 'DELETE',
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getCustomers());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  return (
    <Container maxWidth="xxl" className="app-container customer">
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h4">Customers</Typography>
        <ThemeButton onClick={createCutomer} variant="contained" color="success" startIcon={<AddCircleIcon fontSize="small" />}>
          Create Customer
        </ThemeButton>
      </Box>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        onRowStatusChange={onRowStatusChange}
        defaultRowsPerPage={10}
        maxHeight={653}
        filterKeys={['name', 'sectorName', 'industryName']}
        customToolbar={Types.toolbarTypes.CUSTOMER}
        toolbarProps={toolbarProps}
        handleRowClick={handleRowClick}
        // handleSelectionClick={handleSelectionClick}
        rows={customerRows}
        stickyHeader
        onRowDelete={onDelete}
      />
    </Container>
  );
};

export default CustomerListPage;
