import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { ThemeButton } from './button';

const ConfirmDialog = (props) => {
  const { title, children, setOpen, onConfirm, open, selectedField, cancelText, confirmText, isLoading, onModalClose } = props;

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={onModalClose || handleClose} fullWidth maxWidth="sm">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <ThemeButton variant="outlined" onClick={onModalClose || handleClose} color="error">
          {cancelText || 'Cancel'}
        </ThemeButton>
        <ThemeButton
          variant="contained"
          onClick={() => {
            // setOpen(false);
            onConfirm(selectedField);
          }}
          color="primary"
          loading={isLoading}
          // size="small"
        >
          {confirmText || 'Yes'}
        </ThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onModalClose: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedField: PropTypes.any,
};
