import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Card, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { ThemeButton } from '../../components/button';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import axiosInstance from '../../utils/axiosInstance';
import { getDownloadLinks } from '../studiesPage/studiesSlice';

const StatusCard = ({ status, fileName, filekey, downloadTime, extension, category, downloadLinkID }) => {
  const handleDownloadClick = async () => {
    if (filekey) {
      try {
        // Make the API call for each photo URL
        const response = await axiosInstance({
          url: 'downloads/file',
          method: 'POST',
          data: {
            filekey, // Use the URL in the API call
            type: extension === 'xlsx' ? 'report' : 'photo',
            downloadLinkID,
          },
          responseType: 'blob',
        });
        if (response.status !== 200) {
          console.log('Failed to fetch file for URL:', filekey);
          return; // Skip this photo and continue with the next one
        }

        // Create a temporary URL for the file
        const fileUrl = window.URL.createObjectURL(response.data);

        // Create an invisible <a> tag to trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName; // Use the filename from the URL as the download name
        document.body.appendChild(link);
        link.click();

        // Clean up: remove the <a> tag after the download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl); // Free up memory by revoking the object URL
      } catch (error) {
        console.log('Error downloading photo:', error);
      }
    }
  };
  const params = useParams();

  const dispatch = useDispatch();
  const clearLink = async () => {
    try {
      // Make the API call for each photo URL
      const response = await axiosInstance({
        url: 'downloads/delete/file',
        method: 'DELETE',
        data: {
          filekey, // Use the URL in the API call
          type: extension === 'xlsx' ? 'report' : 'photo',
          downloadLinkID,
        },
      });

      if (response?.data && response?.data?.success) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: get(response, 'data.message'),
          })
        );
        dispatch(
          getDownloadLinks({
            projectID: params?.id,
          })
        );
      }
    } catch (error) {
      console.log('Error downloading photo:', error);
    }
  };
  const formattedTime = useMemo(() => {
    if (downloadTime) {
      const time = dayjs(downloadTime);
      return time.format('YYYY-MM-DD HH:mm:ss');
    }
    return '';
  }, [downloadTime]);
  const getIconName = () => {
    if (extension === 'xlsx') {
      return 'vscode-icons:file-type-excel';
    }
    if (extension === 'zip') {
      return 'formkit:zip';
    }
    return '';
  };
  return (
    <Card
      sx={{
        margin: '5px 10px',
        px: '10px',
        py: 2,
        position: 'relative',
        cursor: 'pointer',
        ...(status === 'PENDING' ? { paddingTop: '40px' } : {}),
      }}
      onClick={handleDownloadClick}
    >
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <Stack alignItems="center" gap direction="row">
          {status === 'COMPLETED' && <Iconify icon={getIconName()} width="2rem" height="1.2rem" />}
          <Stack direction="column">
            {status === 'PENDING' && (
              <Box sx={{ position: 'absolute', top: '10px', right: '14px' }}>
                <ThemeButton
                  onClick={clearLink}
                  size="small"
                  fullWidth
                  style={{ color: 'red', width: 'fit-content', minWidth: 'unset' }}
                  variant="text"
                >
                  <ClearRoundedIcon fontSize="small" />
                </ThemeButton>
              </Box>
            )}
            <Typography variant="body2" fontWeight="600" style={{ paddingLeft: '4px' }}>
              {fileName}
            </Typography>
            <Typography fontWeight="600" variant="caption" style={{ paddingLeft: '4px' }}>
              {status === 'COMPLETED' ? formattedTime : category}
            </Typography>
          </Stack>
        </Stack>
        {status === 'COMPLETED' && (
          <IconButton color="success">
            <DownloadIcon />
          </IconButton>
        )}
      </Stack>
      {status === 'PENDING' && (
        <Stack direction="column" style={{ paddingLeft: '4px' }}>
          <Typography variant="subtitle2">Generating...</Typography>
          <LinearProgress color="success" />
        </Stack>
      )}
    </Card>
  );
};

StatusCard.propTypes = {
  status: PropTypes.string,
  fileName: PropTypes.string,
  filekey: PropTypes.string,
  extension: PropTypes.string,
  category: PropTypes.string,
  downloadLinkID: PropTypes.string,
  downloadTime: PropTypes.any,
};

export default StatusCard;

// status={get(response, 'status')}
// fileName={get(response, 'fileName')}
// downloadTime={get(response, 'downloadTime')}
// extension={get(response, 'extension')}
