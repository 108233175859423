import { Alert, Stack, TableCell, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const LogsNestedRow = ({ row, nestedRowProps }) => {
  if (!row?.changes || row?.changes?.length <= 0) {
    return (
      <TableRow>
        <TableCell sx={{ py: 2 }} align="center" colSpan={12}>
          <Typography variant="body2" sx={{ color: '#84909C' }}>
            No Changes
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow sx={{ bgcolor: '#f9fafb' }}>
      <TableCell sx={{ py: 1 }} colSpan={12}>
        <Stack direction="column" gap>
          {row?.changes?.map(
            (rowMessage) =>
              rowMessage && (
                <Alert icon={false} color="info" variant="standard">
                  {rowMessage}
                </Alert>
              )
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

LogsNestedRow.propTypes = {
  row: PropTypes.any.isRequired,
  nestedRowProps: PropTypes.object,
};

export default LogsNestedRow;
