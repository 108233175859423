import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Container, Grid, InputAdornment, Skeleton, Typography } from '@mui/material';
import { get, has } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThemeButton } from '../../components/button';
import FormInput from '../../components/formComponents/FormInput';
import { settingsSchema } from '../../utils/schema';
import { getSettings, updateSettings } from './settingsSlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';

const SettingsPage = () => {
  const { settings, isLoading, isUpdating } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      passwordExpiryIntervalAdmin: 0,
      passwordExpiryIntervalUser: 0,
      elementsCount: 0,
      SDMultiplyBy: 0,
      dataCoverage: [
        {
          countIsEqualTo: 0,
          countIsEqualToColor: '#ff0000',
        },
        { countIsGTE: 0, countIsLTE: 10, countIsGTEAndLTEColor: '#ffff00' },
        { nextCountIsGTEColor: '#008000', nextCountIsGTE: 10 },
      ],
    },
    resolver: yupResolver(settingsSchema),
  });
  const { register, handleSubmit, formState, setValue, control, watch } = form;
  const { errors } = formState;
  const onSubmit = (values) => {
    if (settings?._id) {
      dispatch(updateSettings({ ...values, _id: settings?._id })).then((response) => {
        if (get(response, 'payload.success')) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Settings Updated Successfully'),
            })
          );
          dispatch(getSettings());
        } else {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      });
    }
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); // This will take you back to the previous page
  };
  const fetchSettings = async () => {
    await dispatch(getSettings()).then((response) => {
      if (get(response, 'payload.success')) {
        if (get(response, 'payload.data')) {
          setValue('SDMultiplyBy', get(response, 'payload.data.SDMultiplyBy'));
          setValue('elementsCount', get(response, 'payload.data.elementsCount'));
          setValue('passwordExpiryIntervalAdmin', get(response, 'payload.data.passwordExpiryIntervalAdmin'));
          setValue('passwordExpiryIntervalUser', get(response, 'payload.data.passwordExpiryIntervalUser'));
          setValue('dataCoverage.0.countIsEqualTo', get(response, 'payload.data.dataCoverage[0].countIsEqualTo'));
          setValue('dataCoverage.0.countIsEqualToColor', get(response, 'payload.data.dataCoverage[0].countIsEqualToColor'));
          setValue('dataCoverage.1.countIsGTE', get(response, 'payload.data.dataCoverage[1].countIsGTE'));
          setValue('dataCoverage.1.countIsGTEAndLTEColor', get(response, 'payload.data.dataCoverage[1].countIsGTEAndLTEColor'));
          setValue('dataCoverage.1.countIsLTE', get(response, 'payload.data.dataCoverage[1].countIsLTE'));
          setValue('dataCoverage.2.nextCountIsGTE', get(response, 'payload.data.dataCoverage[2].nextCountIsGTE'));
          setValue('dataCoverage.2.nextCountIsGTEColor', get(response, 'payload.data.dataCoverage[2].nextCountIsGTEColor'));
        }
      }
    });
  };
  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const firstLineErrorMessage = useMemo(() => {
    let message = '';
    if (has(errors, 'dataCoverage.0.countIsEqualTo') || has(errors, 'dataCoverage.0.countIsEqualToColor')) {
      if (!has(errors, 'dataCoverage.0.countIsEqualTo')) {
        message = get(errors, 'dataCoverage.0.countIsEqualToColor.message', '');
      } else if (!has(errors, 'dataCoverage.0.countIsEqualToColor')) {
        message = get(errors, 'dataCoverage.0.countIsEqualTo.message', '');
      } else if (has(errors, 'dataCoverage.0.countIsEqualToColor') && has(errors, 'dataCoverage.0.countIsEqualTo')) {
        message = `${get(errors, 'dataCoverage.0.countIsEqualTo.message', '')}, ${get(errors, 'dataCoverage.0.countIsEqualToColor.message', '')}`;
      }
    }
    return message;
  }, [errors]);
  const secondLineErrorMessage = useMemo(() => {
    let message = '';
    message = `${has(errors, 'dataCoverage.1.countIsGTE') || has(errors, 'dataCoverage.1.countIsLTE') ? get(errors, 'dataCoverage.1.countIsGTE.message') || get(errors, 'dataCoverage.1.countIsLTE.message') : ''}${has(errors, 'dataCoverage.1.countIsGTEAndLTEColor') ? ', ' : ''} ${has(errors, 'dataCoverage.1.countIsGTEAndLTEColor') ? get(errors, 'dataCoverage.1.countIsGTEAndLTEColor.message') : ''}`;
    return message;
  }, [errors]);
  const thirdLineErrorMessage = useMemo(() => {
    let message = '';
    message = `${has(errors, 'dataCoverage.2.nextCountIsGTE') ? get(errors, 'dataCoverage.2.nextCountIsGTE.message') : ''}${has(errors, 'dataCoverage.2.nextCountIsGTEColor') ? ', ' : ''} ${has(errors, 'dataCoverage.2.nextCountIsGTEColor') ? get(errors, 'dataCoverage.2.nextCountIsGTEColor.message') : ''}`;
    return message;
  }, [errors]);
  const passwordExpiryIntervalAdminFields = register('passwordExpiryIntervalAdmin');
  const passwordExpiryIntervalAdmin = watch('passwordExpiryIntervalAdmin');
  const passwordExpiryIntervalUserFields = register('passwordExpiryIntervalUser');
  const passwordExpiryIntervalUser = watch('passwordExpiryIntervalUser');
  return (
    <Container maxWidth="xxl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Card
            sx={{
              p: 5,
              ml: 2,
              mt: 4,
              '& input': { textAlign: 'center' },
              '& .MuiFormControl-root ': { mx: 0.6 },
              '& .count-input': { mt: '-4px', mb: 2 },
              width: '100%',
            }}
          >
            <Grid container spacing={3}>
              <Grid className="data-coverage" item xs={12} md={12} lg={8} xl={7}>
                <Typography mb={1} variant="h4">
                  Data Coverage Report
                </Typography>
                {isLoading ? (
                  <Skeleton variant="rounded" width="100%" height={202} />
                ) : (
                  <>
                    <p>
                      If the count is{' '}
                      <FormInput
                        type="number"
                        className="count-input"
                        InputProps={{ ...register('dataCoverage.0.countIsEqualTo') }}
                        height={30}
                        size="small"
                        fullWidth={false}
                        width={60}
                        fontSize="small"
                        textAlign="center"
                        error={has(errors, 'dataCoverage.0.countIsEqualTo')}
                        // helperText={
                        //   get(errors, 'dataCoverage.0.countIsEqualTo') && get(errors, 'dataCoverage.0.countIsEqualTo.message')
                        // }
                      />
                      , then the column will be color
                      <FormInput
                        InputProps={{ ...register('dataCoverage.0.countIsEqualToColor') }}
                        type="color"
                        className="count-input"
                        height={30}
                        size="small"
                        fullWidth={false}
                        width={60}
                        fontSize="small"
                        error={has(errors, 'dataCoverage.0.countIsEqualToColor')}
                        // helperText={
                        //   get(errors, 'dataCoverage.0.countIsEqualToColor') &&
                        //   get(errors, 'dataCoverage.0.countIsEqualToColor.message')
                        // }
                      />
                      <small className="error-label">{firstLineErrorMessage}</small>
                    </p>
                    <p>
                      If the count is greater than equal to{' '}
                      <FormInput
                        type="number"
                        className="count-input"
                        InputProps={{ ...register('dataCoverage.1.countIsGTE') }}
                        height={30}
                        size="small"
                        fullWidth={false}
                        width={60}
                        fontSize="small"
                        error={has(errors, 'dataCoverage.1.countIsGTE')}
                        // helperText={get(errors, 'dataCoverage.1.countIsGTE') && get(errors, 'dataCoverage.1.countIsGTE.message')}
                      />
                      and less than equal to
                      <FormInput
                        className="count-input"
                        type="number"
                        InputProps={{ ...register('dataCoverage.1.countIsLTE') }}
                        height={30}
                        size="small"
                        fullWidth={false}
                        width={60}
                        fontSize="small"
                        error={has(errors, 'dataCoverage.1.countIsLTE')}
                        // helperText={get(errors, 'dataCoverage.1.countIsLTE') && get(errors, 'dataCoverage.1.countIsLTE.message')}
                      />
                      , then the column will be
                      <FormInput
                        className="count-input"
                        InputProps={{ ...register('dataCoverage.1.countIsGTEAndLTEColor') }}
                        type="color"
                        height={30}
                        size="small"
                        fullWidth={false}
                        width={60}
                        fontSize="small"
                        error={has(errors, 'dataCoverage.1.countIsGTEAndLTEColor')}
                        // helperText={
                        //   get(errors, 'dataCoverage.1.countIsGTEAndLTEColor') &&
                        //   get(errors, 'dataCoverage.1.countIsGTEAndLTEColor.message')
                        // }
                      />
                      <small className="error-label">{secondLineErrorMessage}</small>
                    </p>
                    <p>
                      If the count is greater than
                      <FormInput
                        className="count-input"
                        type="number"
                        InputProps={{ ...register('dataCoverage.2.nextCountIsGTE') }}
                        height={30}
                        size="small"
                        fullWidth={false}
                        width={60}
                        fontSize="small"
                        error={has(errors, 'dataCoverage.2.nextCountIsGTE')}
                        // helperText={
                        //   get(errors, 'dataCoverage.2.nextCountIsGTE') && get(errors, 'dataCoverage.2.nextCountIsGTE.message')
                        // }
                      />{' '}
                      , then the column will be
                      <FormInput
                        className="count-input"
                        InputProps={{ ...register('dataCoverage.2.nextCountIsGTEColor') }}
                        type="color"
                        height={30}
                        size="small"
                        fullWidth={false}
                        width={60}
                        fontSize="small"
                        error={has(errors, 'dataCoverage.2.nextCountIsGTEColor')}
                        // helperText={
                        //   get(errors, 'dataCoverage.2.nextCountIsGTEColor') &&
                        //   get(errors, 'dataCoverage.2.nextCountIsGTEColor.message')
                        // }
                      />
                      <small className="error-label">{thirdLineErrorMessage}</small>
                    </p>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={4} xl={3} spacing={2}>
                <Grid container spacing={2}>
                  <Grid className="password-expiry" item xs={12} md={12} lg={12}>
                    <Typography mb={1} variant="h4">
                      Password Expiry
                    </Typography>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height={90} />
                    ) : (
                      <Grid justifyContent="space-between" container spacing={2}>
                        <Grid item xs={12} md={6} lg={6} xl={5}>
                          <FormInput
                            // width={200}
                            type="number"
                            label="Field User"
                            placeholder="Select Interval"
                            InputProps={{
                              // ...register('passwordExpiryIntervalUser'),
                              name: passwordExpiryIntervalUserFields.name,
                              onblur: passwordExpiryIntervalUserFields.onBlur,
                              ref: passwordExpiryIntervalUserFields.ref,
                              value: passwordExpiryIntervalUser,
                              onChange: (event) => {
                                const newValue = event.target.value;
                                if (newValue === '' || (Number(newValue) <= 12 && Number(newValue) >= 0)) {
                                  return passwordExpiryIntervalAdminFields.onChange(event);
                                }
                                return null;
                              },
                              endAdornment: (
                                <InputAdornment
                                  sx={{ pl: 1, '& .MuiTypography-root': { fontSize: '0.82rem !important' } }}
                                  position="start"
                                >
                                  Months
                                </InputAdornment>
                              ),
                            }}
                            error={!!errors?.passwordExpiryIntervalUser}
                            helperText={errors?.passwordExpiryIntervalUser && errors?.passwordExpiryIntervalUser?.message}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={5}>
                          <FormInput
                            // width={200}
                            type="number"
                            label="Admin User"
                            placeholder="Select Interval"
                            InputProps={{
                              // ...register('passwordExpiryIntervalAdmin'),
                              name: passwordExpiryIntervalAdminFields.name,
                              onblur: passwordExpiryIntervalAdminFields.onBlur,
                              ref: passwordExpiryIntervalAdminFields.ref,
                              value: passwordExpiryIntervalAdmin,
                              onChange: (event) => {
                                const newValue = event.target.value;
                                if (newValue === '' || (Number(newValue) <= 12 && Number(newValue) >= 0)) {
                                  return passwordExpiryIntervalAdminFields.onChange(event);
                                }
                                return null;
                              },
                              endAdornment: (
                                <InputAdornment
                                  sx={{ pl: 1, '& .MuiTypography-root': { fontSize: '0.82rem !important' } }}
                                  position="start"
                                >
                                  Months
                                </InputAdornment>
                              ),
                            }}
                            error={!!errors?.passwordExpiryIntervalAdmin}
                            helperText={errors?.passwordExpiryIntervalAdmin && errors?.passwordExpiryIntervalAdmin?.message}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid className="data-summary" item xs={12} md={12} lg={12}>
                    <Typography mb={1} variant="h4">
                      Data Summary Page
                    </Typography>
                    {isLoading ? (
                      <Skeleton variant="rounded" width="100%" height={90} />
                    ) : (
                      <Grid justifyContent="space-between" container spacing={2}>
                        <Grid item xs={12} md={6} lg={6} xl={5}>
                          <FormInput
                            // width={200}
                            type="number"
                            label="Elements count"
                            placeholder="Enter Number"
                            InputProps={{
                              ...register('elementsCount'),
                            }}
                            error={!!errors?.elementsCount}
                            helperText={errors?.elementsCount && errors?.elementsCount?.message}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={5}>
                          <FormInput
                            // width={200}
                            type="number"
                            label="S.D. Multiply by"
                            placeholder="Enter Number"
                            InputProps={{
                              ...register('SDMultiplyBy'),
                            }}
                            error={!!errors?.SDMultiplyBy}
                            helperText={errors?.SDMultiplyBy && errors?.SDMultiplyBy?.message}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} mt={5} sx={{ textAlign: 'end' }}>
              <ThemeButton sx={{ mx: 1 }} type="button" onClick={handleBack} color="error" variant="outlined">
                Cancel
              </ThemeButton>
              <ThemeButton loading={isUpdating} type="submit" variant="contained">
                Save
              </ThemeButton>
            </Grid>
          </Card>
        </Grid>
      </form>
      <DevTool control={control} />
    </Container>
  );
};

export default SettingsPage;
