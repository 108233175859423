import { Icon } from '@iconify/react';
import AddIcon from '@mui/icons-material/Add';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import { Avatar, Box, Card, Chip, Container, Grid, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Make sure English locale is loaded
import { startCase } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getStudyLabel } from '../../components/StudiesComponent';
import { ThemeButton } from '../../components/button';
import { ReusableTabs } from '../../components/reusableTabs';
import { APP_ROUTER_BASE_URL, getUserPermissionLabel } from '../../utils';
import { getIndustries } from '../industry/industrySlice';
import { getSectors } from '../sector/sectorsSlice';
import AdminUsers from './AdminUsers';
import CustomerLocations from './CustomerLocations';
import FieldUsers from './FieldUsers';
import { getAdminUsersByCustomer, getCustomers, getFieldUsersByCustomer, getSingleCustomer } from './customerSlice';

const SingleCustomer = () => {
  const { customerID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoadingSingleCustomer,
    singleCustomer,
    isAdminUsersLoading,
    isFieldUsersLoading,
    fieldUsers,
    adminUsers,
    customers,
    isLoading,
  } = useSelector((state) => state.customer);
  const { sectors } = useSelector((state) => state.sectors);
  const { industries } = useSelector((state) => state.industry);
  useEffect(() => {
    if (!sectors || !sectors?.length) {
      dispatch(getSectors());
    }
    if (!industries || !industries?.length) {
      dispatch(getIndustries());
    }
  }, [dispatch, industries, sectors]);
  useEffect(() => {
    dispatch(getCustomers({ status: 'active' }));
  }, [dispatch]);
  const customerOptions = useMemo(() => {
    if (customers && customers?.length >= 0) {
      return customers?.map((customer) => ({ label: customer?.name, value: customer?._id }));
    }
    return [];
  }, [customers]);
  const fetchAdminUsers = () => {
    if (customerID) {
      dispatch(getAdminUsersByCustomer(customerID));
    }
  };
  const fetchFieldUsers = () => {
    if (customerID) {
      dispatch(getFieldUsersByCustomer(customerID));
    }
  };
  useEffect(() => {
    if (customerID) {
      dispatch(getAdminUsersByCustomer(customerID));
      dispatch(getFieldUsersByCustomer(customerID));
    }
  }, [customerID, dispatch]);

  useEffect(() => {
    if (customerID) {
      dispatch(getSingleCustomer(customerID));
    }
  }, [customerID, dispatch]);
  const tabs = [
    // { label: 'Locations', value: 'locations' },
    { label: 'Admin Users', value: 'adminUsers' },
    { label: 'Field Users', value: 'fieldUsers' },
  ];
  const [selectedTab, setSelectedTab] = useState('adminUsers');
  const [isAdminModalopen, setIsAdminModalopen] = useState(false);
  const [isFieldModalopen, setIsFieldModalopen] = useState(false);
  const handleEditCustomer = () => {
    if (customerID) {
      navigate(`${APP_ROUTER_BASE_URL}customer/edit/${customerID}`);
    }
  };
  const onTabChange = (event, newValue) => setSelectedTab(newValue);
  const renderTabContent = () => {
    switch (selectedTab) {
      case 'locations':
        return <CustomerLocations />;
      case 'adminUsers':
        return (
          <AdminUsers
            isModalopen={isAdminModalopen}
            setisModalopen={setIsAdminModalopen}
            fetchAdminUsers={fetchAdminUsers}
            adminUsers={adminUsers}
            isLoading={isAdminUsersLoading}
            customerOptions={customerOptions}
          />
        );
      case 'fieldUsers':
        return (
          <FieldUsers
            isModalopen={isFieldModalopen}
            setisModalopen={setIsFieldModalopen}
            fetchFieldUsers={fetchFieldUsers}
            fieldUsers={fieldUsers}
            isLoading={isFieldUsersLoading}
            customerOptions={customerOptions}
          />
        );
      default:
        return null;
    }
  };
  if (customerID) {
    return (
      <Container maxWidth="xxl">
        <Typography sx={{ mb: 2 }} variant="h4">
          Customer Details
        </Typography>
        {isLoadingSingleCustomer ? (
          <Grid spacing={2} container>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Skeleton variant="rounded" height={396} />
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <Skeleton variant="rounded" height={396} />
            </Grid>
          </Grid>
        ) : (
          singleCustomer && (
            <Grid spacing={2} container>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card sx={{ width: '100%' }}>
                  <Grid rowSpacing={2} mx={2} my container>
                    <Grid display="flex" alignItems="center" item lg={6} sm={6} md={12} xs={12}>
                      <Avatar
                        sx={{
                          // bgcolor: '#00c0f333',
                          bgcolor: 'transparent',
                          border: '0.5px solid #c7c7c7',
                          borderRadius: '8px',
                          // '& .MuiAvatar-fallback': { fill: '#00c0f3' },
                          width: 90,
                          height: 90,
                          ml: { xl: 3, lg: 1, md: 1, sm: 3 },
                        }}
                        // src={`https://server106.absolutebyte.co.uk/${singleCustomer?.image}`}
                      >
                        <img
                          src={singleCustomer?.image ? `/${singleCustomer.image}` : '/assets/icons/placeholder_customer.jpg'}
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop
                            e.target.src = '/assets/icons/placeholder_customer.jpg';
                          }}
                          alt="profile"
                          style={{ width: '82px', borderRadius: '8px', objectFit: 'cover' }}
                        />
                      </Avatar>
                    </Grid>
                    <Grid item lg={6} sm={6} md={12} xs={12}>
                      <Stack>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '18px',
                            color: '#000',
                            mr: 2,
                          }}
                          variant="subtitle1"
                        >
                          {singleCustomer?.name && startCase(singleCustomer?.name)}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '.9375rem',
                            color: '#808285',
                            wordWrap: 'break-word',
                            mr: 2,
                          }}
                          variant="body1"
                        >
                          {`${(singleCustomer && !!sectors?.length && sectors?.find((sector) => sector?._id === singleCustomer?.sectorID)?.name) || ''}/${(singleCustomer && !!industries?.length && industries?.find((industry) => industry?._id === singleCustomer?.industryID)?.name) || ''}`}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item lg={6} sm={6} md={12} xs={12}>
                      <Chip
                        label={singleCustomer?.status && singleCustomer?.status === 'active' ? 'Active' : 'Archived'}
                        sx={{ px: 2, bgcolor: '#98cb4f', color: 'white', minWidth: '66%' }}
                        variant="contained"
                      />
                    </Grid>
                    <Grid item lg={6} sm={6} md={12} xs={12}>
                      <Stack alignItems="center" direction="row" gap>
                        <EventBusyOutlinedIcon sx={{ color: '#808285' }} fontSize="small" />
                        <Typography variant="body1">
                          {/* {singleCustomer?.expiryDate && dayjs(singleCustomer?.expiryDate).locale('en').format('MMMM DD, YYYY')} */}
                          {singleCustomer?.expiryDate && dayjs(singleCustomer?.expiryDate).locale('en').format('DD/MM/YYYY')}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid mt={2} item lg={6} sm={6} md={12} xs={12}>
                      {/* <Stack direction="row" gap> */}
                      <Stack direction="column" gap>
                        <Typography mb textTransform="uppercase" color="#808285" variant="subtitle2">
                          STUDY / APP PERMISSIONS
                        </Typography>
                        {singleCustomer?.studyTypes &&
                          singleCustomer?.studyTypes?.length > 0 &&
                          singleCustomer?.studyTypes?.map(
                            (study) =>
                              study !== 4 && (
                                <Stack direction="row" alignItems="center" gap>
                                  {/* <CheckCircleOutlineIcon fontSize="small" /> */}
                                  {/* <Icon fontSize={15} icon="simple-line-icons:check" /> */}
                                  <Icon icon="gg:check-o" />
                                  <Typography variant="body1">{getStudyLabel(study)}</Typography>
                                </Stack>
                              )
                          )}
                      </Stack>
                    </Grid>
                    <Grid mt={2} item lg={6} sm={6} md={12} xs={12}>
                      {/* <Stack direction="column" gap>
                        {singleCustomer?.userPermission && (
                          <Typography mb textTransform="uppercase" color="#808285" variant="subtitle2">
                            USER PERMISSION
                          </Typography>
                        )}
                        {singleCustomer?.userPermission && (
                          <Stack direction="row" alignItems="center" gap>
                            <Icon icon="gg:check-o" />
                            <Typography variant="body1">{getUserPermissionLabel(singleCustomer?.userPermission)}</Typography>
                          </Stack>
                        )}
                      </Stack> */}
                      {/* </Stack> */}
                    </Grid>
                  </Grid>
                  <ThemeButton
                    sx={{ m: 2 }}
                    onClick={handleEditCustomer}
                    variant="outlined"
                    startIcon={<Icon icon="eva:edit-outline" />}
                  >
                    Edit Customer
                  </ThemeButton>
                  {/* <Stack spacing={2} m={2}>
              <Stack gap={2} mx={5} direction="row">
                <Avatar sx={{ width: 56, height: 56 }} />
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '18px',
                      color: '#000',
                    }}
                    variant="subtitle1"
                  >
                    {singleCustomer?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '.9375rem',
                      color: '#808285',
                    }}
                    variant="body1"
                  >
                    Logistics/Warehousing
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" gap>
                <Chip
                  label={singleCustomer?.status && singleCustomer?.status === 'active' ? 'Active' : 'Archieved'}
                  sx={{ px: 2, bgcolor: '#98cb4f', color: 'white', minWidth: '36%' }}
                  variant="contained"
                />
                <Stack alignItems="flex-start" direction="row" gap>
                  <EventBusyOutlinedIcon />
                  <Typography>October 30, 2023</Typography>
                </Stack>
              </Stack>
            </Stack> */}
                </Card>
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Card sx={{ width: '100%' }}>
                  <Card sx={{ borderBottom: '1px solid #eee' }}>
                    <Stack alignItems="center" direction="row" justifyContent="space-between">
                      <ReusableTabs tabs={tabs} selectedTab={selectedTab} handleChange={onTabChange} />
                      {selectedTab === 'fieldUsers' ? (
                        <ThemeButton
                          sx={{ height: '34px', mr: 1 }}
                          size="small"
                          variant="contained"
                          color="success"
                          onClick={() => setIsFieldModalopen(true)}
                          startIcon={<AddIcon />}
                        >
                          Create Field User
                        </ThemeButton>
                      ) : (
                        <ThemeButton
                          sx={{ height: '34px', mr: 1 }}
                          size="small"
                          variant="contained"
                          color="success"
                          onClick={() => setIsAdminModalopen(true)}
                          startIcon={<AddIcon />}
                        >
                          Create Admin User
                        </ThemeButton>
                      )}
                    </Stack>
                  </Card>
                  <Box sx={{ p: 2, bgcolor: '#f9fafb', width: '100%' }}>{renderTabContent()}</Box>
                </Card>
              </Grid>
            </Grid>
          )
        )}
      </Container>
    );
  }
  return (
    <Container maxWidth="xxl">
      <Typography variant="h4">Can't found the Customer</Typography>
    </Container>
  );
};

export default SingleCustomer;
