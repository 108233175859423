import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Divider, IconButton, ListItemText, Stack, SvgIcon, Typography, keyframes, styled, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import NavSection from '../../../components/navSection';
import { StyledNavItem, StyledNavItemIcon } from '../../../components/navSection/styles';
import Scrollbar from '../../../components/scrollbar';
import logo from '../../../logo/logo.png';
// eslint-disable-next-line import/no-duplicates
import svgLogo from '../../../logo/retime_logo.svg';
import { toggleNav } from '../dashboardSlice';
import navConfig, { NAV_WIDTH, drawerWidth, icon } from './config';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { getRole, logoutUser } from '../../../pages/login/loginSlice';
// eslint-disable-next-line import/no-duplicates
import { ReactComponent as SvgColor } from '../../../logo/retime_logo.svg';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 8px)`,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [isModelOpen, setModelOpen] = useState(false);
  const { isNavExpanded } = useSelector((state) => state.dashboard);
  const { userName, role, userDetails } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [delayed, setDelayed] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const roles = jwtToken && jwtDecode(jwtToken);
  useEffect(() => {
    let timer;
    if (isNavExpanded) {
      timer = setTimeout(() => {
        setDelayed(true);
      }, 400); // Adjust the delay time as needed
    } else {
      setDelayed(false); // Reset delayed to false when isCollapsed is false
    }
    return () => clearTimeout(timer);
  }, [isNavExpanded]);
  useEffect(() => {
    if (roles !== undefined && (!userName || !userDetails)) {
      dispatch(getRole());
    }
  }, [dispatch, roles, userDetails, userName]);
  const toggleSidebar = () => {
    // setOpen(!open);
    dispatch(toggleNav());
  };
  const onConfirm = () => {
    dispatch(logoutUser());
    window.location = '/login';
    cookies.remove('token', { path: '/' });
  };
  const onProfileClick = () => {
    if (userDetails?._id) {
      navigate(`profile/edit/${userDetails?._id}`);
    }
  };
  const renderContent = (
    <Scrollbar
      sx={
        {
          // height: 1,
          // '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
          // overflow:'hidden'
        }
      }
    >
      <NavSection data={navConfig} />
    </Scrollbar>
  );
  const renderStatus = (userRole) => {
    if (userRole === 1) {
      return 'Super User';
    }
    if (userRole === 2) {
      return 'Admin User';
    }
    if (userRole === 3) {
      return 'Field User';
    }
    return '';
  };
  return (
    <>
      <ConfirmDialog title="Log Out" open={isModelOpen} setOpen={setModelOpen} onConfirm={onConfirm}>
        <Typography variant="body1">Are you sure you want to log out ?</Typography>
      </ConfirmDialog>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          // width: { lg: drawerWidth },
          position: 'relative',
          height: '100%',
        }}
      >
        <IconButton
          sx={{
            alignSelf: 'center',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            '&:hover': {
              bgcolor: '#fff',
            },
            // left: isNavExpanded ? '176px' : '90px',
          }}
          onClick={toggleSidebar}
          className="expand-nav-btn"
        >
          {!isNavExpanded ? <ChevronRightIcon fontSize="small" /> : <ChevronLeftIcon fontSize="small" />}
        </IconButton>
        <Drawer
          open={isNavExpanded}
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              // bgcolor: 'background.default',
              bgcolor: '#FFFFFF',
              // borderRightStyle: 'dashed',
              // mt: 8,
            },
          }}
        >
          <Box
            sx={{
              px: 1,
              p: 1,
              // borderRight: '1px solid #ececec',
              // pl: 0,
              // minHeight: 64,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <img src={logo} alt="logo" className="logo" /> */}
            <SvgColor className="retime-logo" />
          </Box>
          <Divider sx={{ mb: 7 }} />

          {/* <DrawerHeader
          sx={{
            display: 'flex',
            flexDirection: isNavExpanded ? 'row' : 'column',
            alignItems: 'center',
            justifyContent: isNavExpanded ? 'flex-end' : 'center',
            // justifyContent: isNavExpanded ? 'space-between' : 'center',
            // mb: 2,
          }}
        >
          <IconButton
            sx={{ alignSelf: 'center', boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' }}
            onClick={toggleSidebar}
          >
            {!isNavExpanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader> */}
          {renderContent}
          <Divider sx={{ marginTop: '20px', marginBottom: '10px' }} />
          <Box
            sx={{
              p: 1,
              // bgcolor: !!isNavExpanded && '	#ececec',
              mb: 2,
              borderRadius: 2,
              mx: 1,
              pt: 2,
              // animation: isNavExpanded && `${colorIn} 0.6s ease-in-out`,
            }}
          >
            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
              {/* <Avatar /> */}
              {!!isNavExpanded && (
                <Stack
                  sx={{
                    opacity: 1,
                    height: '100%',
                    // animation: `${fadeIn} 0.6s ease-in-out`,
                    width: 164,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3c4555',
                      fontSize: '0.77rem',
                      // wordWrap: 'break-word',
                      // whiteSpace: 'initial',
                      whiteSpace: delayed ? 'initial' : 'unset',
                      textTransform: 'uppercase',
                      textOverflow: 'clip',
                    }}
                  >
                    {userName}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#3c4555b0',
                      fontSize: '0.7rem',
                      wordWrap: 'break-word',
                      whiteSpace: 'initial',
                      textTransform: 'uppercase',
                    }}
                    textTransform="uppercase"
                  >
                    {renderStatus(role)}
                  </Typography>
                </Stack>
              )}
            </Stack>
            {/* const { title, path, icon, info } = item; */}
            {role !== 3 && (
              <StyledNavItem
                onClick={onProfileClick}
                sx={{
                  color: 'primary.dark',
                  ...(isNavExpanded && { padding: 1, py: 0.5, mt: 1, '&.MuiButtonBase-root': { height: 'unset' } }),
                }}
              >
                {/* <StyledNavItem sx={{ color: 'primary.dark', padding: 1, mt: 1, '&.MuiButtonBase-root': { height: 'unset' } }}> */}
                <StyledNavItemIcon sx={{ ...(isNavExpanded && { '&.MuiListItemIcon-root': { minWidth: 'unset' } }) }}>
                  {/* <StyledNavItemIcon sx={{ '&.MuiListItemIcon-root': { minWidth: 'unset' } }}> */}
                  {icon('ic_profileIcon')}
                </StyledNavItemIcon>
                {!!isNavExpanded && <ListItemText sx={{ ml: 1 }} disableTypography primary="Profile" />}
              </StyledNavItem>
            )}
            {/* <StyledNavItem sx={{ color: 'error.main', padding: 1, '&.MuiButtonBase-root': { height: 'unset' } }}> */}
            <StyledNavItem
              onClick={() => setModelOpen(true)}
              sx={{
                color: 'error.main',
                ...(isNavExpanded && { padding: 1, py: 0.5, '&.MuiButtonBase-root': { height: 'unset' } }),
              }}
            >
              <StyledNavItemIcon sx={{ ...(isNavExpanded && { '&.MuiListItemIcon-root': { minWidth: 'unset' } }) }}>
                {icon('ic_logout2')}
              </StyledNavItemIcon>
              {/* <StyledNavItemIcon sx={{ '&.MuiListItemIcon-root': { minWidth: 'unset' } }}>{icon('ic_logout2')}</StyledNavItemIcon> */}
              {!!isNavExpanded && <ListItemText sx={{ ml: 1 }} disableTypography primary="Log Out" />}
            </StyledNavItem>
            {/* <NavItem item={{ title: 'Log Out', icon: icon('ic_profileIcon') }} /> */}
          </Box>
          <Typography variant="body2" sx={{ px: isNavExpanded ? 2 : 1, pb: 1, color: '#c7c8ca' }}>
            Version 4.0.0
          </Typography>
        </Drawer>
      </Box>
    </>
  );
};

export default Sidebar;
