import PropTypes from 'prop-types';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';

const ThemeTimePicker = ({ label, control, name, register }) => (
  <Stack
    sx={{
      '&.custom-stack .MuiTextField-root': {
        minWidth: 'unset',
      },
    }}
    className="custom-stack"
  >
    {label && (
      <Typography sx={{ fontSize: '0.86rem' }} textAlign="start">
        {label}
      </Typography>
    )}
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const value = field.value ? dayjs(field.value, 'HH:mm') : null;
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
              <TimePicker
                {...field}
                value={value}
                onChange={(newValue) => {
                  field.onChange(dayjs(newValue).format('HH:mm')); // Ensure it's stored as 'HH:mm'
                }}
                format="HH:mm" // Force 24-hour format display
                slotProps={{
                  textField: {
                    fullWidth: false,
                    variant: 'outlined', // Change to 'standard' or 'filled' if needed
                    InputProps: {
                      size: 'small',
                      style: {
                        borderRadius: '25px',
                      },
                    },
                  },
                  openPickerButton: {
                    sx: {
                      '& .MuiIconButton-root': {
                        padding: '2px', // Reduce button padding
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '22px', // Reduce icon size
                      },
                    },
                  },
                  label: {
                    sx: {
                      lineHeight: '0.775em',
                    },
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        );
      }}
    />
  </Stack>
);

ThemeTimePicker.propTypes = {
  control: PropTypes.any,
  register: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default ThemeTimePicker;
