/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Container, DialogActions, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, startCase } from 'lodash';
import StatusIndicator from '../../components/status';
import { addIndustry, editIndustry, getIndustries } from './industrySlice';
import { Types } from '../../types';
import DataTable from '../../components/table';
import { ThemeButton } from '../../components/button';
import ModalComponent from '../../components/ModalComponent';
import FormInput from '../../components/formComponents/FormInput';
import Iconify from '../../components/Iconify';
import CustomSelect from '../../components/select';
import { addIndustrySchema, editIndustrySchema } from '../../utils/schema';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import axiosInstance from '../../utils/axiosInstance';

const IndustryListPage = () => {
  const headCells = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      minWidth: 200,
      titleCase: true,
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
      width: '15%',
    },
  ];
  const [rowToEdit, setRowToEdit] = useState('');
  const form = useForm({
    defaultValues: {
      name: '',
    },
    resolver: rowToEdit ? yupResolver(editIndustrySchema) : yupResolver(addIndustrySchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;

  const [isModalopen, setisModalopen] = useState(false);
  const { industries, isLoading, isAdding } = useSelector((state) => state.industry);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIndustries());
  }, [dispatch]);

  const regularIndustries = useMemo(
    () =>
      !isEmpty(industries) &&
      industries.reduce(
        (acc, industry) => {
          if (industry?.status === 'active') {
            acc.active.push(industry);
          } else if (industry?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(industry);
          }
          acc.default.push(industry);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [industries]
  );

  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [showPassword, setShowPassword] = useState(false);
  const [allStatusModelOpened, setAllStatusModelOpened] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [statusToChange, setStatusToChange] = useState('');
  const [userRows, setUserRows] = useState([]);
  useEffect(() => {
    if (regularIndustries) {
      if (Status === DEFAULT) {
        setUserRows(regularIndustries?.default);
      } else if (Status === ARCHIVED) {
        setUserRows(regularIndustries?.archive);
      } else if (Status === ACTIVE) {
        setUserRows(regularIndustries?.active);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularIndustries]);

  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setUserRows(regularIndustries.default);
      } else if (value === ACTIVE) {
        setUserRows(regularIndustries.active);
      } else if (value === ARCHIVED) {
        setUserRows(regularIndustries.archive);
      }
    }
  };
  const getStatusPayload = (selectedIds, status) => {
    const fetchUrl = 'industries/update/status';
    const payload = {
      industryIDs: selectedIds,
      status,
    };
    return {
      fetchUrl,
      payload,
    };
  };
  const getRemovePayload = (selectedIds) => {
    const deleteUrl = `industries/delete/${selectedIds[0]}`;
    const deletePayload = {
      industryIDs: selectedIds,
    };
    return {
      deleteUrl,
      deletePayload,
    };
  };
  const fetchData = async () => dispatch(getIndustries());
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search System Industry',
    getStatusPayload,
    fetchData,
    getRemovePayload,
  };
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    // setisModalopen(false);
    reset();
    // reset({ permission: '' });
    setShowPassword(false);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const onSubmit = (values) => {
    if (values) {
      if (rowToEdit) {
        dispatch(editIndustry({ ...values, _id: rowToEdit })).then((response) => {
          if (response.payload?.success) {
            closeModal();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
              })
            );
            dispatch(getIndustries());
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(addIndustry(values))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'User Added Successfully'),
                })
              );
              dispatch(getIndustries());
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const openModal = () => setisModalopen(true);
  const onEditClick = async (event, row) => {
    // setRowToEdit((row?._id));
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      openModal();
      try {
        const response = await axiosInstance({
          url: `industries/get/${row?._id}`,
          method: 'GET',
        });
        if (get(response, 'data.data', '')) {
          setValue('name', get(response, 'data.data.name', ''));
        }
      } catch (error) {
        console.log('error', error);
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'industries/update/status',
          method: 'PATCH',
          data: { industryIDs: [row?._id], status },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} System Industry`,
            })
          );
          dispatch(getIndustries());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `industries/delete/${row?._id}`,
          method: 'DELETE',
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getIndustries());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  return (
    <Container maxWidth="xxl">
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title={rowToEdit ? 'Edit System Industry' : 'Create System Industry'}
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <FormInput
              label="Industry Name"
              placeholder="Enter Name"
              InputProps={{
                ...register('name'),
              }}
              error={!!errors?.name}
              helperText={errors?.name && errors?.name?.message}
            />
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isAdding} type="submit" variant="contained">
              {rowToEdit ? 'Save Changes' : 'Create System Industry'}
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h4">System Industries</Typography>
        <ThemeButton onClick={openModal} variant="contained" color="success" startIcon={<AddCircleIcon fontSize="small" />}>
          Create System Industry
        </ThemeButton>
      </Box>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        onRowStatusChange={onRowStatusChange}
        defaultRowsPerPage={10}
        maxHeight={653}
        filterKeys={['name']}
        customToolbar={Types.toolbarTypes.SUPER_USER}
        toolbarProps={toolbarProps}
        // handleSelectionClick={handleSelectionClick}
        rows={userRows}
        stickyHeader
        onRowDelete={onDelete}
      />
    </Container>
  );
};

export default IndustryListPage;
