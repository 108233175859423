import { Box, DialogActions, FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Iconify from '../../components/Iconify';
import ModalComponent from '../../components/ModalComponent';
import { ThemeButton } from '../../components/button';
import FormInput from '../../components/formComponents/FormInput';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { filterEmptyValues } from '../../utils';
import { getHoursMapping, massEditHours } from './hoursMappingSlice';

const HoursMappingToolbar = (props) => {
  const { selected, filterName, onFilterName, searchLable, toolbarProps, handleClearSelection, rows } = props;
  const numSelected = selected?.length;
  const { placeholder } = toolbarProps;

  const handleMassEdit = () => {};
  const form = useForm({
    defaultValues: {
      timeslotLabel: '',
      hourLabel: '',
    },
    // resolver: yupResolver(addElementInProjectSchema),
  });
  const [isModalopen, setisModalopen] = useState(false);
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    // // reset({ permission: '' });
    // // setShowPassword(false);
    // setValue('tasks', []);
    // setOriginalTasks([]);
    // if (rowToEdit) {
    //   setTimeout(() => {
    //     setRowToEdit('');
    //   }, 200);
    // }
  };
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    if (rows && rows?.length > 0 && selected && selected?.length > 0) {
      const payload = selected?.map((selectedId) => {
        const observation = rows?.find((row) => row?._id === selectedId) || null;
        return observation;
      });

      const finalPayload = payload?.map((observation) => {
        const obsObject = {
          timeslotLabel: values?.timeslotLabel || observation?.timeslotLabel,
          hourLabel: observation?.hourLabel,
          _id: observation?._id,
          // name: observation?.name,
        };
        const filteredPayload = filterEmptyValues(obsObject);
        return filteredPayload;
      });
      dispatch(massEditHours(finalPayload))
        .then((response) => {
          if (response?.payload.successCount <= 0) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          }
          closeModal();
          handleClearSelection();
          dispatch(getHoursMapping());
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: 'Something went wrong',
            })
          );
        });
    }
  };
  return (
    <>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title="Mass Edit Hours Mapping"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, p: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={false} onClick={handleSubmit(onSubmit)} type="submit" variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
              <Grid item sm={12} lg={12} md={12}>
                <FormInput
                  label="Time Slot Label"
                  placeholder="Enter Time Slot Label"
                  InputProps={{
                    ...register('timeslotLabel'),
                  }}
                  error={!!errors?.timeslotLabel}
                  helperText={errors?.timeslotLabel && errors?.timeslotLabel?.message}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </ModalComponent>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 0,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
        className="toolbar-wrapper"
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}
        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            <ThemeButton
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="primary"
              onClick={() => setisModalopen(true)}
              startIcon={<Iconify icon="iconamoon:edit-duotone" />}
            >
              {`Mass Edit ${numSelected > 1 ? 'all' : ''}`}
            </ThemeButton>
          </Stack>
        ) : (
          <Grid p={1} container gap>
            <Grid item xs={12} md={4} lg={2.5}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{
                    fontSize: '0.86rem',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || placeholder || 'Search...'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

HoursMappingToolbar.propTypes = {
  selected: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleClearSelection: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
  rows: PropTypes.array,
};

export default HoursMappingToolbar;
