import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  fieldUsers: [],
  isLoading: false,
  isUserAdding: false,
};
export const getFieldUsers = createAsyncThunk('fieldUser/getFieldUsers', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'users/get',
      method: 'POST',
      data: { role: 3, ...payload },
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addFieldUser = createAsyncThunk('superUser/addFieldUser', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'users/register',
      method: 'POST',
      data: { ...data, role: 3 },
    });
    // localdata set
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const editFieldUser = createAsyncThunk('superUser/editFieldUser', async (data, { rejectWithValue }) => {
  if (get(data, '_id')) {
    try {
      const response = await axiosInstance({
        url: `users/update/${data?._id}`,
        method: 'PATCH',
        data: { ...data, role: 3 },
      });
      // localdata set
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});

export const fieldUserSlice = createSlice({
  name: 'fieldUser',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFieldUsers.pending, (state, action) => {
      state.isLoading = true;
      state.fieldUsers = [];
    });
    builder.addCase(getFieldUsers.fulfilled, (state, action) => {
      state.fieldUsers = get(action, 'payload.data', []);
      state.isLoading = false;
    });
    builder.addCase(getFieldUsers.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addFieldUser.pending, (state, action) => {
      state.isUserAdding = true;
    });
    builder.addCase(addFieldUser.fulfilled, (state, action) => {
      // state.adminUsers = get(action, 'payload.data', []);
      state.isUserAdding = false;
    });
    builder.addCase(addFieldUser.rejected, (state, action) => {
      state.isUserAdding = false;
    });
    builder.addCase(editFieldUser.pending, (state, action) => {
      state.isUserAdding = true;
    });
    builder.addCase(editFieldUser.fulfilled, (state, action) => {
      // state.adminUsers = get(action, 'payload.data', []);
      state.isUserAdding = false;
    });
    builder.addCase(editFieldUser.rejected, (state, action) => {
      state.isUserAdding = false;
    });
  },
});

export default fieldUserSlice.reducer;
