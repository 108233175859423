import React from 'react';
import { Button, Link, Stack, Typography } from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import dayjs from 'dayjs';
import { Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

const DigitalClock = ({ disableDate, label, control, name }) => {
  let a;
  return (
    <Stack>
      {label && <Typography textAlign="start">{label}</Typography>}
      <Controller
        name={name}
        control={control}
        // eslint-disable-next-line arrow-body-style
        render={({ field }) => {
          field.value = field?.value ? dayjs().set('minute', field?.value.minutes).set('second', field?.value.seconds) : null;
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{
                  '& .css-1o1ohr1-MuiTypography-root': {
                    textAlign: 'center',
                    // fontWeight: 'bold',
                    whiteSpace: 'pre',
                  },
                  // '& .css-11a8txn-MuiStack-root': { flexDirection: 'column-reverse' },
                }}
                components={['MultiSectionDigitalClock']}
              >
                <DemoItem label={'"Mins" and "Secs"'}>
                  <MultiSectionDigitalClock
                    {...field}
                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                    sx={{
                      '& .css-14mb0cu-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item': {
                        minHeight: 'unset',
                      },
                      '& .MuiMultiSectionDigitalClock-root': {
                        minHeight: 'unset !important',
                        maxHeight: '100px',
                        '&::-webkit-scrollbar': {
                          width: '0.4em',
                        },
                        '&::-webkit-scrollbar-track': {
                          // background: '#f1f1f1',
                          borderRadius: '25px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          // backgroundColor: '#888',
                          backgroundColor: '#919eab3d',
                          borderRadius: '25px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                          background: '#555',
                        },
                      },
                      '& ul': {
                        // Your styles for the ul tag
                        minHeight: 'unset',
                        maxHeight: '100px',
                        '&::-webkit-scrollbar': {
                          width: '0.4em',
                        },
                      },
                      '& .MuiMenuItem-root': {
                        fontSize: '1rem',
                        lineHeight: 0.9,
                        // color: '#fff',
                        width: '40px',
                        marginInline: '8px',
                        height: '30px',
                        borderRadius: '4px',
                      },
                      '& .MuiTouchRipple-root': {
                        maxHeight: '30px !important' /* Use !important to ensure the override */,
                      },
                    }}
                    onChange={(value, state) => {
                      const timeValue = dayjs(value);
                      // field.onChange({ hours: timeValue.hour(), minutes: timeValue.minute() });
                      field.onChange({ minutes: timeValue.minute(), seconds: timeValue.second() });
                    }}
                    ampm={false}
                    views={['minutes', 'seconds']}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          );
        }}
      />
    </Stack>
  );
};

DigitalClock.propTypes = {
  control: PropTypes.any,
  register: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  disableDate: PropTypes.bool,
};

export default DigitalClock;
