import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const getStudyLabel = (studyType) => {
  switch (studyType) {
    case 1:
      return 'Efficiency';
    case 2:
      return 'Role';
    case 3:
      return 'Activity';
    case 4:
      return 'Predetermined';
    default:
      return '';
  }
};
export const getStudyColor = (studyType) => {
  switch (studyType) {
    case 1:
      return '#f59d0f';
    case 2:
      return '#784fc2';
    case 3:
      return '#0ba0ef';
    case 4:
      return '#9CC13B';
    default:
      return '';
  }
};

const StudiesComponent = ({ studyTypes, pl, minWidth }) => {
  if (studyTypes && studyTypes?.length > 0) {
    return (
      // <Box pl={pl || 10} display="flex" justifyContent="start">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 0.5,
          width: '100%',
          flexWrap: 'nowrap', // Ensure everything stays on one line
        }}
      >
        {studyTypes.map((studyType, index) => (
          <Typography
            component="p"
            sx={{ color: getStudyColor(studyType), textAlign: 'left', fontSize: '0.835rem' }}
            variant="subtitle2"
          >
            {getStudyLabel(studyType)}
          </Typography>
        ))}
      </Box>
    );
  }
  return null;
};
export default StudiesComponent;

StudiesComponent.propTypes = {
  studyTypes: PropTypes.array.isRequired,
  pl: PropTypes.any,
  minWidth: PropTypes.any,
};
