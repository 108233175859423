import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visibleFilteredRows: [],
};

const uiSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setVisibleFilteredRows: (state, action) => {
      state.visibleFilteredRows = action.payload;
    },
  },
});
export const { setVisibleFilteredRows } = uiSlice.actions;
export default uiSlice.reducer;
