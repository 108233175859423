import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  elements: [],
  isLoading: false,
  isAdding: false,
  visibleFilteredRows: [],
  isElementexporting: false,
};
export const getElements = createAsyncThunk('element/getElements', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'elements/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const exportElement = createAsyncThunk('element/exportElement', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'elements/download',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const addElement = createAsyncThunk('element/addElement', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'elements/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
// eslint-disable-next-line consistent-return
export const editElement = createAsyncThunk('element/editElement', async (data, { rejectWithValue }) => {
  if (data?._id) {
    try {
      const response = await axiosInstance({
        url: `elements/update/${data?._id}`,
        method: 'PATCH',
        data,
      });
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});
const elementSlice = createSlice({
  name: 'element',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getElements.pending, (state, actions) => {
      state.isLoading = true;
      state.elements = [];
    });
    builder.addCase(getElements.fulfilled, (state, action) => {
      state.isLoading = false;
      state.elements = action.payload.data;
    });
    builder.addCase(getElements.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addElement.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(addElement.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addElement.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(editElement.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(editElement.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(editElement.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(exportElement.pending, (state, actions) => {
      state.isElementexporting = true;
    });
    builder.addCase(exportElement.fulfilled, (state, action) => {
      state.isElementexporting = false;
    });
    builder.addCase(exportElement.rejected, (state, action) => {
      state.isElementexporting = false;
    });
  },
});
export default elementSlice.reducer;
