import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActionButton, ThemeButton } from '../../components/button';
import Iconify from '../../components/Iconify';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { addMissingObservations } from './studiesSlice';
import ColorLegend from '../../components/ColorLegend';

const AddObsToolbar = (props) => {
  const {
    setFilterName,
    selected,
    handleSelectionClick,
    filterName,
    onFilterName,
    handleClearSelection,
    toolbarProps,
    visibleRows,
  } = props;
  const numSelected = selected?.length;
  const { currentMissingRound, closeMissingModal, fetchStudy } = toolbarProps;
  const { addEcLoading } = useSelector((state) => state.studies);
  const dispatch = useDispatch();
  const { id: projectID } = useParams();
  const snackBarMessage = (message) => {
    if (message) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: message,
        })
      );
      fetchStudy();
    }
  };
  const handleOkClicked = async () => {
    if (visibleRows && visibleRows?.length > 0 && selected && selected?.length > 0) {
      const filteredRows = visibleRows.filter((row) => selected.includes(row?._id));
      const selectedRows = filteredRows.map((row) => {
        const payload = {
          projectID,
        };
        if (row?.studyID) {
          payload.studyID = row?.studyID;
        }
        if (currentMissingRound?.roundID) {
          payload.roundID = currentMissingRound?.roundID;
        }
        if (row?.areaID) {
          payload.areaID = row?.areaID;
        }
        if (row?.elementID) {
          payload.elementID = row?.elementID;
        }
        if (row?.roleID) {
          payload.roleID = row?.roleID;
        }
        if (row?.rating) {
          payload.rating = row?.rating;
        }
        if (row?.frequency) {
          payload.frequency = row?.frequency;
        }
        if (row?.notes) {
          payload.notes = row?.notes;
        }
        return payload;
      });
      dispatch(addMissingObservations({ selectedRows, closeMissingModal, snackBarMessage }));
    }
  };
  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 2,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : null} */}

      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}

      {numSelected > 0 ? (
        <Stack gap direction="row" whiteSpace="nowrap">
          <ActionButton
            type=""
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            variant="outlined"
            loading={addEcLoading}
            onClick={handleOkClicked}
          >
            Ok
          </ActionButton>
        </Stack>
      ) : (
        <Grid p={1} container justifyContent="space-between">
          <Grid item lg={3} md={4.5} xs={12}>
            <FormControl fullWidth>
              <OutlinedInput
                // size='small'
                sx={{
                  fontSize: '0.86rem',
                  // mt: '5px',
                  // mb: '16px',
                  borderRadius: 25,
                  lineHeight: '1.9375em',
                  '.MuiInputBase-input': {
                    height: '0.660em',
                    py: '15px',
                  },
                }}
                fullWidth
                // size='small'
                value={filterName}
                onChange={onFilterName}
                placeholder="Search Observations"
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} md={5} xs={12}>
            <Stack gap={2} direction="row">
              <ColorLegend color="#00c0f385" label="Row added via HUB" />
              <ColorLegend color="error.main" label="Row with data removed from the HUB" />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

AddObsToolbar.propTypes = {
  selected: PropTypes.array,
  visibleRows: PropTypes.array,
  handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleClearSelection: PropTypes.func,
  setFilterName: PropTypes.func,
  toolbarProps: PropTypes.object,
};

export default AddObsToolbar;
